import type {InjectionKey} from 'vue';
import type {PortfolioRepository} from '~/types/domain/repository/PortfolioRepository';
import type {ProfileRepository} from '~/types/domain/repository/ProfileRepository';
import type {RecommendationRepository} from '~/types/domain/repository/RecommendationRepository';
import type {AdminEventRepository} from '~/types/domain/repository/AdminEventRepository';
import {portfolioRepository} from '~/types/infra/repository/PortfolioRepositoryAPI';
import {profileRepository} from '~/types/infra/repository/ProfileRepositoryAPI';
import {recommendationRepository} from '~/types/infra/repository/RecommendationRepositoryAPI';
import {adminEventRepository} from '~/types/infra/repository/AdminEventRepositoryAPI';
import type {ShortlistRepository} from '~/types/domain/repository/ShortlistRepository';
import {shortlistRepository} from '~/types/infra/repository/ShortlistRepositoryAPI';
import type {WebPreferencesRepository} from '~/types/domain/repository/WebPreferencesRepository';
import {webPreferencesRepository} from '~/types/infra/repository/WebPreferencesRepositoryAPI';

const repositoriesInjectionKeys = {
    portfolio: Symbol('portfolio') as InjectionKey<PortfolioRepository>,
    profile: Symbol('profile') as InjectionKey<ProfileRepository>,
    shortlist: Symbol('shortlist') as InjectionKey<ShortlistRepository>,
    recommendation: Symbol('recommendation') as InjectionKey<RecommendationRepository>,
    adminEvents: Symbol('adminEvents') as InjectionKey<AdminEventRepository>,
    webPreferences: Symbol('webPreferences') as InjectionKey<WebPreferencesRepository>,
};

function getRepositories(): Record<symbol, any> {
    return {
        [repositoriesInjectionKeys.portfolio as symbol]: portfolioRepository,
        [repositoriesInjectionKeys.profile as symbol]: profileRepository,
        [repositoriesInjectionKeys.shortlist as symbol]: shortlistRepository,
        [repositoriesInjectionKeys.recommendation as symbol]: recommendationRepository,
        [repositoriesInjectionKeys.adminEvents as symbol]: adminEventRepository,
        [repositoriesInjectionKeys.webPreferences as symbol]: webPreferencesRepository,
    };
}

export const RegisteredRepositories = {
    all: () => getRepositories(),
    injectionKeys: repositoriesInjectionKeys,
};
