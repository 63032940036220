/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { UpdateProfileSkillRequest } from './';
import {
    UpdateProfileSkillRequestFromJSON,
    UpdateProfileSkillRequestFromJSONTyped,
    UpdateProfileSkillRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProfileSkillsRequest
 */
export interface UpdateProfileSkillsRequest {
    /**
     * 
     * @type {Array<UpdateProfileSkillRequest>}
     * @memberof UpdateProfileSkillsRequest
     */
    selectedSkillsOrder: Array<UpdateProfileSkillRequest>;
    /**
     * 
     * @type {Array<UpdateProfileSkillRequest>}
     * @memberof UpdateProfileSkillsRequest
     */
    topSkills: Array<UpdateProfileSkillRequest>;
}

/**
 * Check if a given object implements the UpdateProfileSkillsRequest interface.
 */
export function instanceOfUpdateProfileSkillsRequest(value: object): value is UpdateProfileSkillsRequest {
    if (!('selectedSkillsOrder' in value) || value['selectedSkillsOrder'] === undefined) return false;
    if (!('topSkills' in value) || value['topSkills'] === undefined) return false;
    return true;
}

export function UpdateProfileSkillsRequestFromJSON(json: any): UpdateProfileSkillsRequest {
    return UpdateProfileSkillsRequestFromJSONTyped(json, false);
}

export function UpdateProfileSkillsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileSkillsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'selectedSkillsOrder': ((json['selectedSkillsOrder'] as Array<any>).map(UpdateProfileSkillRequestFromJSON)),
        'topSkills': ((json['topSkills'] as Array<any>).map(UpdateProfileSkillRequestFromJSON)),
    };
}

export function UpdateProfileSkillsRequestToJSON(value?: UpdateProfileSkillsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selectedSkillsOrder': ((value['selectedSkillsOrder'] as Array<any>).map((v) => UpdateProfileSkillRequestToJSON(v))),
        'topSkills': ((value['topSkills'] as Array<any>).map((v) => UpdateProfileSkillRequestToJSON(v))),
    };
}

