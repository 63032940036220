import type {
    ActivityAreaResource,
    AddressResource,
    DateAsString,
    ExperienceResource,
    LogoResource,
    RecommendationResource,
} from '~/types/api-raw-types';

export class Experience {
    area: ActivityAreaResource;
    asFreelance: boolean;
    commentCount: number;
    company: string;
    companyId: string;
    currentJob: boolean;
    description: string;
    endDate?: DateAsString;
    id: string;
    location?: AddressResource;
    logo: LogoResource;
    organizationName: string;
    recommendationCount: number;
    recos: RecommendationResource[];
    startDate: DateAsString;
    tags: string[];
    title: string;

    constructor(params: ExperienceResource) {
        this.area = params.area;
        this.asFreelance = params.asFreelance;
        this.commentCount = params.commentCount;
        this.company = params.company;
        this.companyId = params.companyId;
        this.currentJob = params.currentJob;
        this.description = params.description;
        this.endDate = params.endDate || undefined;
        this.id = params.id;
        this.location = params.location;
        this.logo = params.logo;
        this.organizationName = params.organizationName;
        this.recommendationCount = params.recommendationCount;
        this.recos = params.recos;
        this.startDate = params.startDate;
        this.tags = params.tags;
        this.title = params.title;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Experiences {
    items: Experience[];

    constructor(params: ExperienceResource[]) {
        this.items = params.map((experienceResource) => new Experience(experienceResource));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
