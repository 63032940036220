import type {CurrencyResource, PriceResource} from '~/types/api-raw-types';

export class Currency {
    code: string;
    symbol: string;

    constructor(params: CurrencyResource) {
        this.code = params.code;
        this.symbol = params.symbol;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Price {
    currency: Currency;
    currencyCode: string;
    minPrice: number;
    price: number;
    priceHidden: boolean;
    reducedFeeRate: number;
    standardFeeRate: number;

    constructor(params: PriceResource) {
        this.currency = new Currency(params.currency);
        this.currencyCode = params.currencyCode;
        this.minPrice = params.minPrice;
        this.price = params.price;
        this.priceHidden = params.priceHidden;
        this.reducedFeeRate = params.reducedFeeRate;
        this.standardFeeRate = params.standardFeeRate;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
