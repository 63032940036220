import type {AdminEventRequest} from '~/types/api-raw-types';
import type {Profile} from '~/types/domain/Profile';
import type {AdminEventRepository} from '~/types/domain/repository/AdminEventRepository';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import {useUniversalPost} from '#imports';

function addAdminEvent(adminEvent: AdminEventRequest, profile: Profile): Promise<void> {
    const url = extractLink(profile.links, 'postAdminEvent');
    return useUniversalPost(url, {body: {...adminEvent}});
}

export const adminEventRepository: AdminEventRepository = {
    addAdminEvent,
};
