import {definePayloadPlugin, definePayloadReducer, definePayloadReviver} from '#imports';
import {Translations} from '~/store/translation/translationStore';
import {PriceStat} from '~/types/PriceStat';
import {AdminEvent, AdminEvents} from '~/types/domain/AdminEvents';
import {Appraisal, AppraisalAverageRating, Appraisals} from '~/types/domain/Appraisals';
import {Breadcrumb, BreadcrumbCategory, BreadcrumbFamily, BreadcrumbItem, BreadcrumbItems, BreadcrumbJob} from '~/types/domain/Breadcrumb';
import {Categorization, CategorizationCategory, CategorizationFamily} from '~/types/domain/Categorization';
import {Certification, Certifications} from '~/types/domain/Certifications';
import {CompanySuggestion, CompanySuggestions} from '~/types/domain/CompanySuggestions';
import {CompletionList} from '~/types/domain/CompletionList';
import {DevDetails} from '~/types/domain/DevDetails';
import {Education, Educations} from '~/types/domain/Educations';
import {Experience, Experiences} from '~/types/domain/Experiences';
import {FreelanceCharter} from '~/types/domain/FreelanceCharter';
import {Language, LanguageAndLevel, Languages} from '~/types/domain/Languages';
import {LocationPreferences} from '~/types/domain/LocationPreferences';
import {MissionPreference, MissionPreferences} from '~/types/domain/MissionPreferences';
import {Portfolio, PortfolioItem, PortfolioItemThumbnail, PortfolioItemThumbnails} from '~/types/domain/Portfolio';
import {Currency, Price} from '~/types/domain/Price';
import {Picture, Profile} from '~/types/domain/Profile';
import {ProfileAdmin} from '~/types/domain/ProfileAdmin';
import {ProfileExpertises, ProfileFunctionalSkill, ProfileGlobalSkill, ProfileIndustryExpertise} from '~/types/domain/ProfileExpertises';
import {ProfileWebPreferences} from '~/types/domain/ProfileWebPreferences';
import {Recommendation, Recommendations} from '~/types/domain/Recommendations';
import {SectionsVisibility} from '~/types/domain/SectionsVisibility';
import {Shortlist, ShortlistItem} from '~/types/domain/Shortlist';
import {Siblings} from '~/types/domain/Siblings';
import {SimilarProfile, SimilarProfilePhoto} from '~/types/domain/SimilarProfile';
import {TagsSuggestions, TagSuggestion} from '~/types/domain/TagsSuggestions';
import {TaskCard, TaskCards} from '~/types/domain/TaskCards';
import {MinMaxDateRule, MinMaxRule, MinRule} from '~/types/domain/ValidationRule';
import {CharterStatus, ComplianceCheck, EmailValidation, VerificationChecklist} from '~/types/domain/VerificationChecklist';
import {Visibility, VisibilityAlert} from '~/types/domain/Visibility';
import {Wishlist, Wishlists} from '~/types/domain/Wishlist';
import {FunctionalScope, FunctionalScopesAvailable, FunctionalSkill} from '~/types/domain/skill/Functional';
import {IndustryExpertise, IndustryExpertisesAvailable} from '~/types/domain/skill/Industry';

interface JSONSerializable {
    toJSON(): any;
}

type ClassConstructor<T extends JSONSerializable> = new (...args: any[]) => T;

type ClassRecord = Record<string, ClassConstructor<JSONSerializable>>;

function definePayloadHandlers<T extends JSONSerializable>(classRecord: ClassRecord) {
    Object.entries(classRecord).forEach(([className, ClassConstructor]) => {
        // See https://nuxt.com/docs/api/composables/use-nuxt-app#payload
        definePayloadReducer(className, (value: any): any => {
            return value instanceof ClassConstructor && value.toJSON();
        });
        definePayloadReviver(className, (value: any): T | any => {
            return value;
        });
    });
}

const classRecord: ClassRecord = {
    AdminEvent,
    AdminEvents,
    Appraisal,
    Appraisals,
    AppraisalAverageRating,
    BreadcrumbItem,
    BreadcrumbItems,
    BreadcrumbJob,
    BreadcrumbCategory,
    BreadcrumbFamily,
    Breadcrumb,
    Categorization,
    CategorizationFamily,
    CategorizationCategory,
    Certification,
    Certifications,
    CharterStatus,
    CompanySuggestion,
    CompanySuggestions,
    CompletionList,
    ComplianceCheck,
    Currency,
    DevDetails,
    Education,
    Educations,
    EmailValidation,
    Experience,
    Experiences,
    FreelanceCharter,
    FunctionalSkill,
    FunctionalScope,
    FunctionalScopesAvailable,
    IndustryExpertise,
    IndustryExpertisesAvailable,
    Language,
    LanguageAndLevel,
    Languages,
    LocationPreferences,
    MinMaxDateRule,
    MinMaxRule,
    MinRule,
    MissionPreference,
    MissionPreferences,
    Picture,
    Portfolio,
    PortfolioItem,
    PortfolioItemThumbnail,
    PortfolioItemThumbnails,
    Price,
    PriceStat,
    Profile,
    ProfileAdmin,
    ProfileGlobalSkill,
    ProfileFunctionalSkill,
    ProfileIndustryExpertise,
    ProfileExpertises,
    ProfileWebPreferences,
    Recommendation,
    Recommendations,
    SectionsVisibility,
    Shortlist,
    ShortlistItem,
    Siblings,
    SimilarProfile,
    SimilarProfilePhoto,
    TagSuggestion,
    TagsSuggestions,
    TaskCard,
    TaskCards,
    Translations,
    VerificationChecklist,
    Visibility,
    VisibilityAlert,
    Wishlist,
    Wishlists,
};

export default definePayloadPlugin(() => {
    definePayloadHandlers(classRecord);
});
