import type {FieldName, VisibilityAlertResource, VisibilityResource, VisibilityType} from '~/types/api-raw-types';

export class VisibilityAlert {
    field: FieldName;
    message: string;
    rawMessage: string;
    type: VisibilityType;

    constructor(visibilityAlertResource: VisibilityAlertResource) {
        this.field = visibilityAlertResource.field;
        this.message = visibilityAlertResource.message;
        this.rawMessage = visibilityAlertResource.rawMessage;
        this.type = visibilityAlertResource.type;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
export class Visibility {
    alerts: VisibilityAlert[];
    profileCompleted: boolean;
    status: VisibilityType;

    constructor(params: VisibilityResource) {
        this.alerts = params.alerts.map((resource) => new VisibilityAlert(resource));
        this.profileCompleted = params.profileCompleted;
        this.status = params.status;
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static defaultVisibility() {
        return new Visibility({
            alerts: [],
            profileCompleted: false,
            status: 'GOOD',
        });
    }
}
