import type {ExpertiseLevel, ProfileFunctionalSkill, ProfileGlobalSkill, ProfileIndustryExpertise} from '~/types/domain/ProfileExpertises';

export type ExpertiseItem = {
    id: string;
    label: string;
    translatedLabel?: string;
    level?: ExpertiseLevel;
    seoUrl?: string;
    topSkill?: boolean;
};

export function globalSkillsToExpertiseItems(globalSkills: ProfileGlobalSkill[]): ExpertiseItem[] {
    return globalSkills.map((skill, index) => {
        return {
            id: index.toString(),
            label: skill.label,
            translatedLabel: skill.translatedLabel,
            level: skill.level,
            seoUrl: skill.seoUrl,
            topSkill: skill.preferred,
        };
    });
}

export function functionalSkillsToExpertiseItems(functionalSkills: ProfileFunctionalSkill[]): ExpertiseItem[] {
    return functionalSkills.map((skill) => {
        return {
            id: skill.id,
            label: skill.label,
            level: skill.level,
            seoUrl: skill.seoUrl,
        };
    });
}

export function industryToExpertiseItems(industries: ProfileIndustryExpertise[]): ExpertiseItem[] {
    return industries.map((skill) => {
        return {
            id: skill.id,
            label: skill.label,
            seoUrl: skill.seoUrl,
        };
    });
}

export function combineGlobalAndFunctionalSkills(globalSkills: ProfileGlobalSkill[], functionalSkills: ProfileFunctionalSkill[]) {
    return [...globalSkillsToExpertiseItems(globalSkills), ...functionalSkillsToExpertiseItems(functionalSkills)];
}

export function filterTopSkills(skills: ExpertiseItem[]) {
    return skills.filter((skill) => skill.topSkill === true);
}

export function filterAdditionalSkills(skills: ExpertiseItem[]) {
    return skills.filter((skill) => skill.topSkill !== true);
}
