import type {DateAsString, FreelanceCharterResource} from '~/types/api-raw-types';
import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';

export class FreelanceCharter extends AbstractResource {
    invalidUntil: DateAsString;
    invalidated: boolean;
    signed: boolean;
    valid: boolean;

    constructor(params: FreelanceCharterResource) {
        super(params);
        this.invalidUntil = params.invalidUntil;
        this.invalidated = params.invalidated;
        this.signed = params.signed;
        this.valid = params.valid;
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static defaultCharter(): FreelanceCharter {
        return new FreelanceCharter({
            invalidUntil: '',
            invalidated: false,
            signed: true,
            valid: true,
            _links: [],
        });
    }
}
