import type {ActivityLocationResource, AddressResource, MissionPreferenceResource, WorkplacePreferenceResourceValue} from '@/types/api-raw-types';
import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';

export class LocationPreferences extends AbstractResource {
    address: AddressResource;
    preference: WorkplacePreferenceResourceValue;
    addressRadius: number;
    activityLocations: ActivityLocationResource[];
    override links: any;
    willingnessToTravelInternationally: boolean;

    constructor(locationPreferencesResources: MissionPreferenceResource) {
        super(locationPreferencesResources);
        this.address = locationPreferencesResources.workplace.address;
        this.preference = locationPreferencesResources.workplace.preference;
        this.activityLocations = locationPreferencesResources.workplace.activityLocations;
        this.addressRadius = locationPreferencesResources.workplace.addressRadius;
        this.links = locationPreferencesResources._links;
        this.willingnessToTravelInternationally = locationPreferencesResources.workplace.willingnessToTravelInternationally;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
