import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import type {RecommendationResource, RecommendationsResource, ResourceLink} from '~/types/api-raw-types';

export class Recommendation extends AbstractResource {
    accepted: boolean;
    accountId: string;
    authorCompanyName: string;
    authorFirstName: string;
    authorImgUrl: string;
    authorLastName: string;
    authorProfileUrl: string;
    experienceId: string;
    override id: string;
    textContent: string;
    lastUpdateDate: Date;
    override links: any;

    constructor(params: RecommendationResource) {
        super(params);
        this.accepted = params.accepted;
        this.accountId = params.accountId;
        this.authorCompanyName = params.authorCompanyName;
        this.authorFirstName = params.authorFirstName;
        this.authorImgUrl = params.authorImgUrl;
        this.authorLastName = params.authorLastName;
        this.authorProfileUrl = params.authorProfileUrl;
        this.experienceId = params.experienceId;
        this.id = params.id;
        this.textContent = params.recoText;
        this.lastUpdateDate = new Date(params.recoTextUpdateDate);
        this.links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Recommendations {
    recommendations: Recommendation[];
    recommendationsCount: number;
    links: ResourceLink[];

    constructor(params: RecommendationsResource) {
        this.recommendations = params.recommendations.map((resource) => new Recommendation(resource));
        this.recommendationsCount = this.recommendations.length;
        this.links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
