import {buildRegistry, buildExtendedRegistry} from './RegistryEntriesBuilder';
import type {ExtendedResourceRegistryEntries, LinkEntry, ResourceRegistryEntries} from '../registry/registry';

export class ResourceRegistry {
    entries: ResourceRegistryEntries = {};
    extendedEntries: ExtendedResourceRegistryEntries = {};

    constructor(links: LinkEntry[] = [], extendedRegistry = false) {
        if (extendedRegistry) {
            this.extendedEntries = buildExtendedRegistry(links);
        } else {
            this.entries = buildRegistry(links);
        }
    }
}
