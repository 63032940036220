import {Client, createClient, getRepositoryEndpoint} from '@prismicio/client';
import {routes} from '../../helpers/link-resolver';
import {guestHost, useRuntimeConfig} from '#imports';

export default function usePrismicClient(): Client {
    const runtimeConfig = useRuntimeConfig().public;

    const prismicCacheEnabled = runtimeConfig.prismicCacheEnabled as boolean | undefined;
    const prismicAccessToken = runtimeConfig.prismicAccessToken as string | undefined;
    const prismicRepository = runtimeConfig.prismicRepository as string | undefined;

    if (prismicCacheEnabled) {
        const host = guestHost();
        return createClient(host + '/cms/api/prismic', {accessToken: prismicAccessToken, routes});
    } else {
        if (!prismicAccessToken) {
            throw new Error('Prismic token not defined in configuration.');
        }
        if (!prismicRepository) {
            throw new Error('Prismic repository not defined in configuration.');
        }
        const endpoint = getRepositoryEndpoint(prismicRepository);
        return createClient(endpoint, {accessToken: prismicAccessToken, routes});
    }
}

export {Client as PrismicClient};
