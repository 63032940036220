import type {WishlistResource} from '~/types/api-raw-types';

export class Wishlist {
    id: string;
    name: string;
    ownerFirstname;
    profiles: string[];
    sharedWithinCompany: boolean;
    slug: string;
    url: string;

    constructor(params: WishlistResource) {
        this.id = params.id;
        this.name = params.name;
        this.ownerFirstname = params.ownerFirstname;
        this.profiles = params.profiles;
        this.sharedWithinCompany = params.sharedWithinCompany;
        this.slug = params.slug;
        this.url = params.url;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Wishlists {
    items: Wishlist[] = [];

    constructor(params?: WishlistResource[]) {
        this.items = params?.map((wishlistItem) => new Wishlist(wishlistItem)) || [];
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static default(): Wishlists {
        return new Wishlists();
    }
}
