import {defineStore, storeToRefs} from 'pinia';
import {ref} from 'vue';
import {useProfileStore} from '~/store/profileStore';
import {computed, useLocale, useSectionsVisibility} from '#imports';
import {translationRepository} from '~/types/infra/repository/TranslationRepositoryApi';
import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';

export type TranslationRequest = {
    section: string;
    index: number | string;
    field: string;
    originalContent: string;
    translatedContent?: string;
};

export class Translations {
    requests: TranslationRequest[] = [];
    translated: boolean = false;
    restored: boolean = false;

    toJSON() {
        return {
            ...this,
        };
    }
}

export enum TranslationDirection {
    TRANSLATE,
    RESTORE,
}

export const useTranslationStore = defineStore('translations', () => {
    const {profile} = storeToRefs(useProfileStore());
    const {locale} = useLocale();
    const currentLang = ref<string>(locale.value.split('-')[0] || 'en');
    const translations = ref<Translations>(new Translations());
    const isTranslationDone = computed(() => translations.value.translated && translations.value.restored);
    const loading = ref(false);

    const isTranslationActivated = ref(useSectionsVisibility(SectionsVisibilityType.TRANSLATION));

    function findTranslation(section: string, field: string, index: number): TranslationRequest | undefined {
        return translations.value.requests.find(
            (translation) => translation.section === section && translation.field === field && translation.index === index,
        );
    }

    async function translateAll(requests: TranslationRequest[]) {
        if (requests.length === 0) {
            return;
        }

        loading.value = true;
        const field = requests[0].field;
        const section = requests[0].section;
        if (translations.value.translated) {
            translations.value.restored = true;
            loading.value = false;
            return;
        }

        const contents = requests.map((req) => {
            if (req.originalContent === '') {
                req.originalContent = 'N / A';
            }

            return req.originalContent;
        });

        for (let i = 0; i < requests.length; i++) {
            const currentTranslation = findTranslation(section, field, i);
            if (!currentTranslation) {
                translations.value.requests.push(requests.find((request) => request.index === i)!);
            }
        }

        const translatedContents = await translationRepository.translate(currentLang.value, contents, profile.value!);

        for (let i = 0; i < translatedContents.length; i++) {
            findTranslation(section, field, i)!.translatedContent = translatedContents[i];
        }

        translations.value.translated = true;
        translations.value.restored = true;
        loading.value = false;
    }

    function restore() {
        translations.value.restored = false;
    }

    /**
     * Helper function for switching from original to translated content and reverse
     * @param section the section identifier in which the translation applies
     * @param field the field identifier in which the translation is replaced
     * @param translationDirection the action to translate or restore
     * @param contentTransformation external function applying the original or translated content
     */
    function replaceTranslationContent(
        section: string,
        field: string,
        translationDirection: TranslationDirection,
        contentTransformation: (content: string, index: number, field: string) => void = () => {},
    ) {
        const doRestore = translationDirection === TranslationDirection.RESTORE;

        const translatedContents = translations.value.requests
            .filter((translation) => translation.section === section && translation.field === field)
            .map((translation) => (doRestore ? translation.originalContent : translation.translatedContent!));

        for (let index = 0; index < translatedContents.length; index++) {
            contentTransformation(translatedContents[index], index, field);
        }

        if (doRestore) {
            restore();
        }
    }

    return {
        isTranslationActivated,
        isTranslationDone,
        loading,
        translations,
        currentLang,
        replaceTranslationContent,
        translateAll,
    };
});
