import type {DateAsString, PhotoResource} from '~/types/api-raw-types';
import {Picture} from '~/types/domain/Profile';

export interface ShortlistItemResource {
    id: string;
    shortlistId: string;
    profileId: string;
    headLine: string;
    accountId: string;
    conversationId: string;
    deleted: boolean;
    firstName: string;
    normalizedLastName: string;
    normalizedFullName: string;
    photo: PhotoResource;
    seoUrl: string;
    profileViewId: string;
    superMalter1: boolean;
    superMalter2: boolean;
    superMalter3: boolean;
}

export interface ShortlistResource {
    id: string;
    authorAccountId?: string;
    correlationId: string;
    creationDate: DateAsString;
    title: string;
    searchId: string;
    expiryTime: DateAsString;
    maxItems: number;
    items: ShortlistItemResource[];
    origin: string;
    freelancersListId?: string;
    profileViewId?: string;
}

export interface UpsertShortlistRequest {
    searchId: string | null;
    title: string;
    profileViewId: string;
    accountIds: string[];
}

export class ShortlistItem {
    id: string;
    shortlistId: string;
    profileId: string;
    headLine: string;
    accountId: string;
    conversationId: string;
    deleted: boolean;
    firstName: string;
    normalizedLastName: string;
    normalizedFullName: string;
    photo: Picture;
    seoUrl: string;
    profileViewId: string;
    superMalter1: boolean;
    superMalter2: boolean;
    superMalter3: boolean;

    constructor(params: ShortlistItemResource) {
        this.id = params.id;
        this.shortlistId = params.shortlistId;
        this.profileId = params.profileId;
        this.headLine = params.headLine;
        this.accountId = params.accountId;
        this.conversationId = params.conversationId;
        this.deleted = params.deleted;
        this.firstName = params.firstName;
        this.normalizedLastName = params.normalizedLastName;
        this.normalizedFullName = params.normalizedFullName;
        this.photo = new Picture(params.photo);
        this.seoUrl = params.seoUrl;
        this.profileViewId = params.profileViewId;
        this.superMalter1 = params.superMalter1;
        this.superMalter2 = params.superMalter2;
        this.superMalter3 = params.superMalter3;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Shortlist {
    id: string;
    authorAccountId?: string;
    correlationId: string;
    creationDate: DateAsString;
    title: string;
    searchId: string;
    expiryTime: DateAsString;
    maxItems: number;
    items: ShortlistItem[];
    origin: string;
    freelancersListId?: string;
    profileViewId?: string;

    constructor(params: ShortlistResource) {
        this.id = params.id;
        this.authorAccountId = params.authorAccountId;
        this.correlationId = params.correlationId;
        this.creationDate = params.creationDate;
        this.title = params.title;
        this.searchId = params.searchId;
        this.expiryTime = params.expiryTime;
        this.maxItems = params.maxItems;
        this.items = params.items.map((itemResource) => new ShortlistItem(itemResource));
        this.origin = params.origin;
        this.freelancersListId = params.freelancersListId;
        this.profileViewId = params.profileViewId;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
