import {defineNuxtPlugin} from '#imports';
import * as sessionReplay from '@amplitude/session-replay-browser';

export default defineNuxtPlugin({
    name: 'tracking-session-replay',
    parallel: true,
    setup() {
        return {
            provide: {
                trackingSessionReplay: sessionReplay,
            },
        };
    },
});
