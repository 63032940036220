import type {HotReloadedSearchProfileViewPhoto, ProfileRecommendationResource} from '~/types/api-raw-types';

export class SimilarProfilePhoto {
    jpg: string;
    webp: string;

    constructor(HotReloadedSearchProfileViewPhoto: HotReloadedSearchProfileViewPhoto) {
        this.jpg = HotReloadedSearchProfileViewPhoto.jpg;
        this.webp = HotReloadedSearchProfileViewPhoto.webp;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class SimilarProfile {
    canonicalUrl: string;
    firstName: string;
    headline: string;
    normalizedLastName: string;
    numberOfStars: number;
    photo?: SimilarProfilePhoto;
    profileId: string;

    constructor(params: ProfileRecommendationResource) {
        this.canonicalUrl = params.canonicalUrl;
        this.firstName = params.firstName;
        this.headline = params.headline;
        this.normalizedLastName = params.normalizedLastName;
        this.numberOfStars = params.numberOfStars;
        if (params.photo !== null) {
            this.photo = new SimilarProfilePhoto(params.photo);
        }
        this.profileId = params.profileId;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
