import type {ProfileCompletionCheckListResource, ProfileCompletionResource} from '@/types/api-raw-types';

export class CompletionList {
    items: ProfileCompletionCheckListResource;

    constructor(params: ProfileCompletionResource) {
        this.items = params.checkList;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
