import type {AdminEventResource, AdminEventsResource, ResourceLink, TypeView} from '~/types/api-raw-types';
import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';

export class AdminEvent extends AbstractResource {
    authorAccountId: string;
    authorName: string;
    comment: string;
    date: string;
    editable: boolean;
    override id: string;
    rawComment: string;
    type: TypeView;
    typeLabel: string;
    _links: ResourceLink[];

    constructor(params: AdminEventResource) {
        super(params);
        this.authorAccountId = params.authorAccountId;
        this.authorName = params.authorName;
        this.comment = params.comment;
        this.date = params.date;
        this.editable = params.editable;
        this.id = params.id;
        this.rawComment = params.rawComment;
        this.type = params.type;
        this.typeLabel = params.typeLabel;
        this.typeLabel = params.typeLabel;
        this._links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class AdminEvents extends AbstractResource {
    accountId: string;
    events: AdminEvent[];

    constructor(params: AdminEventsResource) {
        super(params);
        this.accountId = params.accountId;
        this.events = params.events.map((event) => new AdminEvent(event));
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static defaultAdminEvents(): AdminEvents {
        return new AdminEvents({
            accountId: '',
            events: [],
            _links: [],
        });
    }
}
