import type {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
import {useProfileStore} from '../store/profileStore';
import {storeToRefs} from 'pinia';
import {useNuxtApp} from '#imports';

export function useSectionsVisibility(sectionName: SectionsVisibilityType): boolean {
    const {$pinia} = useNuxtApp();
    const profileStore = useProfileStore($pinia);
    const {sectionsVisibility} = storeToRefs(profileStore);
    if (sectionsVisibility.value === undefined) return false;
    return sectionsVisibility.value?.visibleSections?.includes(sectionName);
}
