import {guestHost, useUniversalDelete, useUniversalFetch, useUniversalFetchRaw} from '#imports';
import {urlService} from 'vue3-starter/dist/http/UrlService';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {Profile} from '~/types/domain/Profile';
import type {ShortlistRepository} from '../../domain/repository/ShortlistRepository';
import type {ShortlistResource, UpsertShortlistRequest} from '../../domain/Shortlist';
import {Shortlist} from '../../domain/Shortlist';

async function reloadShortlist(profile: Profile): Promise<Shortlist | null> {
    try {
        const url = extractLink(profile.links, 'reloadShortlist');
        const latestShortlistUrl = `${guestHost()}${url}`;
        const shortlistResource = await useUniversalFetch<ShortlistResource>(latestShortlistUrl);
        return new Shortlist(shortlistResource);
    } catch (e) {
        return null;
    }
}

async function upsertShortlist(profile: Profile, request: UpsertShortlistRequest): Promise<string> {
    const url = extractLink(profile.links, 'upsertShortlist');
    const upsertShortlistUrl = `${guestHost()}${url}`;
    const body = new URLSearchParams({
        title: request.title,
        ...(request.searchId ? {searchId: request.searchId} : {}),
        profileViewId: request.profileViewId,
    });
    request.accountIds.forEach((accountId: string, index: number) => {
        body.append(`accountIds[${index}]`, accountId);
    });
    const response = await useUniversalFetchRaw(upsertShortlistUrl, {
        method: 'POST',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        body,
    });

    return response.url;
}

async function removeFromShortlist(profile: Profile, profileId: string): Promise<void> {
    const url = extractLink(profile.links, 'removeFromShortlist');
    const removeFromShortlistUrl = urlService.buildUrl(`${guestHost()}${url}`, profileId);
    await useUniversalDelete(removeFromShortlistUrl);
}

export const shortlistRepository: ShortlistRepository = {
    reloadShortlist,
    upsertShortlist,
    removeFromShortlist,
};
