export type NuxtPayloadValueContainingAndUrl = {
    urlBase64: string;
};

export const NuxtPayloadUrlEncoder = {
    encode: (payloadToEncode: string): NuxtPayloadValueContainingAndUrl | undefined => {
        if (payloadToEncode && /^(\/|http[s]?:\/\/).+/.test(payloadToEncode)) {
            try {
                // URI encode payload to avoid btoa errors
                const encoded = encodeURI(payloadToEncode);
                // base 64 encode
                return {urlBase64: btoa(encoded)};
            } catch {}
        }

        // fallback: do nothing when payload is not an URL nor encoded
    },
    decode: (payloadToDecode: NuxtPayloadValueContainingAndUrl): string => {
        try {
            return decodeURI(atob(payloadToDecode.urlBase64));
        } catch {
            // fallback: return original payload
            return payloadToDecode.urlBase64;
        }
    },
};
