import type {FunctionalScopeResource, FunctionalSkillResource} from '~/types/api-raw-types';

export class FunctionalSkill {
    id: string;
    label: string;

    constructor(params: FunctionalSkillResource) {
        this.id = params.humanId;
        this.label = params.label;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class FunctionalScope {
    // TODO id?
    label: string;
    functionalSkills: FunctionalSkill[];

    constructor(params: FunctionalScopeResource) {
        this.label = params.label;
        this.functionalSkills = params.functionalSkills.map((res) => new FunctionalSkill(res));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class FunctionalScopesAvailable {
    all: FunctionalScope[];

    constructor(params: FunctionalScopeResource[]) {
        this.all = params.map((res) => new FunctionalScope(res));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
