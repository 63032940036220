import {guestHost, useUniversalDelete, useUniversalFetch, useUniversalPost, useUniversalPut} from '#imports';
import {urlService} from 'vue3-starter/dist/http/UrlService';
import {Educations} from '~/types/domain/Educations';
import type {ExperienceLevel} from '~/types/domain/Profile';
import {Picture, Profile} from '~/types/domain/Profile';
import {VerificationChecklist} from '~/types/domain/VerificationChecklist';
import {Breadcrumb} from '~/types/domain/Breadcrumb';
import {Recommendations} from '~/types/domain/Recommendations';

import {TaskCards} from '~/types/domain/TaskCards';
import type {ResourceRegistry} from 'vue3-starter/src/typescript/registry/ResourceRegistry';
import {Experiences} from '~/types/domain/Experiences';
import {FreelanceCharter} from '~/types/domain/FreelanceCharter';
import {ProfileAdmin} from '~/types/domain/ProfileAdmin';
import {ProfileExpertises, type ProfileSelectedSkillSetItem, SelectedSkillType} from '~/types/domain/ProfileExpertises';
import {SimilarProfile} from '~/types/domain/SimilarProfile';
import {Visibility} from '~/types/domain/Visibility';
import {FunctionalScopesAvailable} from '~/types/domain/skill/Functional';
import type {UpdateIndustryExpertiseRequest} from '~/types/domain/skill/Industry';
import {IndustryExpertisesAvailable} from '~/types/domain/skill/Industry';
import {extractLink, extractMaybeLink} from '~/helpers/api/ExtractLinkHelper';
import type {
    InvitationsRequest,
    Skill,
    UpdateActivityLocationsRequest,
    UpdateActivityLocationsResource,
    UpdateBreadcrumbRequest,
    UpdateLanguageRequest,
    UpdatePriceRequest,
} from '~/store/profileStore';
import {PriceStat} from '~/types/PriceStat';
import type {
    AdminEventsResource,
    AppraisalsResource,
    BreadcrumbResource,
    CertificationsResource,
    CompanySuggestionsResource,
    DevDetailsResource,
    EducationsResource,
    ExperienceResource,
    FreelanceCharterResource,
    FunctionalScopeResource,
    IndustryExpertiseResource,
    LogoResource,
    MinMaxRuleResource,
    MissionPreferencesResource,
    PhotoResource,
    PriceStatResource,
    ProfileAdminResource,
    ProfileCompletionResource,
    ProfileExpertisesResource,
    ProfileRecommendationResource,
    ProfileResource,
    RecommendationsResource,
    SectionVisibilityResource,
    TagSuggestionResource,
    UpdateProfileSkillRequest,
    UpdateProfileSkillsRequest,
    UpdateProfileSkillTypeRequest,
    UpdateSeoUrlRequest,
    VisibilityResource,
} from '~/types/api-raw-types';
import {AdminEvents} from '~/types/domain/AdminEvents';
import type {Appraisal} from '~/types/domain/Appraisals';
import {Appraisals} from '~/types/domain/Appraisals';
import {Certifications} from '~/types/domain/Certifications';
import {CompanySuggestions} from '~/types/domain/CompanySuggestions';
import {CompletionList} from '~/types/domain/CompletionList';
import {DevDetails} from '~/types/domain/DevDetails';
import type {RecommendationRequest} from '~/types/domain/RecommendationRequest';
import {SectionsVisibility} from '~/types/domain/SectionsVisibility';
import type {UpdateCertificationRequest} from '~/types/infra/CertificationRequest';
import type {UpdateEducationRequest} from '~/types/infra/EducationRequest';
import type {ExperienceRequest, UpdateExperienceRequest} from '~/types/infra/ExperienceRequest';
import {MissionPreferences} from '~/types/domain/MissionPreferences';
import type {ProfileRepository} from '../../domain/repository/ProfileRepository';

export enum ProfileRelationTypes {
    ONGOING_MISSION = 'ONGOING_MISSION',
    COMPLETED_MISSION = 'COMPLETED_MISSION',
    ONGOING_EXPERIENCE = 'ONGOING_EXPERIENCE',
    PAST_EXPERIENCE = 'PAST_EXPERIENCE',
    FAVORITE = 'FAVORITE',
    FAVORITE_OF_MINE = 'FAVORITE_OF_MINE',
}

export type ProfileRelationResource = {
    type: ProfileRelationTypes;
    collaborator?: {
        name: string;
    };
};

export type ProfileRelationsResource = {
    relations: ProfileRelationResource[];
    name: string;
    logo: LogoResource;
};

export type Collaborators = {experienceRelated: Array<string>; isAddedAsFavorite: boolean};

async function fetchProfile(idOrAlias: string, registry: ResourceRegistry, queryParams: string = ''): Promise<Profile> {
    const profileUrlWithQueryParameters = `${urlService.buildUrl(registry.entries.profile(), idOrAlias)}?${queryParams}`;
    const profileResource = await useUniversalFetch<ProfileResource>(profileUrlWithQueryParameters);

    return new Profile(profileResource);
}
async function fetchAppraisals(profile: Profile): Promise<Appraisals> {
    const url = extractLink(profile.links, 'appraisals');
    const appraisalResource = await useUniversalFetch<AppraisalsResource>(url);
    return new Appraisals(appraisalResource);
}

async function fetchRelations(profile: Profile): Promise<ProfileRelationsResource> {
    const url = extractLink(profile.links, 'relations');
    return await useUniversalFetch<ProfileRelationsResource>(url);
}

async function fetchCompletionList(profile: Profile): Promise<CompletionList> {
    const url = extractLink(profile.links, 'completionList');
    const performancesResource = await useUniversalFetch<ProfileCompletionResource>(url);
    return new CompletionList(performancesResource);
}

async function fetchTaskCards(profile: Profile): Promise<TaskCards> {
    const url = extractLink(profile.links, 'completionList');
    const taskCardResource = await useUniversalFetch<ProfileCompletionResource>(url);
    return new TaskCards(taskCardResource);
}

async function fetchMissionPreferences(profile: Profile): Promise<MissionPreferences> {
    const url = extractLink(profile.locationPreferences.links, 'missionPreferences');
    const missionPrefrencesResource = await useUniversalFetch<MissionPreferencesResource>(url);
    return new MissionPreferences(missionPrefrencesResource);
}

async function fetchRecommendations(profile: Profile): Promise<Recommendations> {
    const url = extractLink(profile.links, 'recommendations');
    const recommendationResource = await useUniversalFetch<RecommendationsResource>(url);
    return new Recommendations(recommendationResource);
}

async function fetchBreadcrumb(profile: Profile): Promise<Breadcrumb> {
    const url = extractLink(profile.links, 'breadcrumb');
    const breadcrumbResource = await useUniversalFetch<BreadcrumbResource>(url);
    return new Breadcrumb(breadcrumbResource);
}

async function fetchVerificationChecklist(profile: Profile): Promise<VerificationChecklist> {
    const url = extractLink(profile.links, 'checklist');
    const checklistResource = await useUniversalFetch<VerificationChecklist>(url);
    return new VerificationChecklist(checklistResource);
}

async function fetchEducations(profile: Profile): Promise<Educations> {
    const url = extractLink(profile.links, 'educations');
    const educationResources = await useUniversalFetch<EducationsResource>(url);
    return new Educations(educationResources.educations);
}

async function fetchDevDetails(profile: Profile): Promise<DevDetails> {
    const url = extractLink(profile.links, 'devDetails');
    const devDetails = await useUniversalFetch<DevDetailsResource>(url);
    return new DevDetails(devDetails);
}

async function fetchVisibility(profile: Profile): Promise<Visibility> {
    try {
        const url = extractLink(profile.links, 'visibility');
        const resource = await useUniversalFetch<VisibilityResource>(url);
        return new Visibility(resource);
    } catch (e) {
        return Visibility.defaultVisibility();
    }
}

async function fetchFreelanceCharter(profile: Profile): Promise<FreelanceCharter> {
    try {
        const url = extractLink(profile.links, 'charter');
        const resource = await useUniversalFetch<FreelanceCharterResource>(url);
        return new FreelanceCharter(resource);
    } catch (e) {
        return FreelanceCharter.defaultCharter();
    }
}

async function fetchProfileAdmin(profile: Profile): Promise<ProfileAdmin> {
    try {
        const url = extractLink(profile.links, 'admin');
        const resource = await useUniversalFetch<ProfileAdminResource>(url);
        return new ProfileAdmin(resource);
    } catch (e) {
        return ProfileAdmin.defaultProfileAdmin();
    }
}

async function fetchAdminEvents(profile: Profile): Promise<AdminEvents> {
    try {
        const url = extractLink(profile.links, 'adminEvents');
        const resource = await useUniversalFetch<AdminEventsResource>(url);
        return new AdminEvents(resource);
    } catch (e) {
        return AdminEvents.defaultAdminEvents();
    }
}

async function fetchSimilarProfiles(profile: Profile): Promise<SimilarProfile[]> {
    try {
        const url = extractLink(profile.links, 'similar');
        const resource = await useUniversalFetch<ProfileRecommendationResource[]>(url);
        return resource.map((r) => new SimilarProfile(r));
    } catch (e) {
        return [];
    }
}

async function fetchCertifications(profile: Profile): Promise<Certifications> {
    const url = extractLink(profile.links, 'certifications');
    const certificationsResource = await useUniversalFetch<CertificationsResource>(url);
    return new Certifications(certificationsResource.certifications);
}

async function fetchExperiences(profile: Profile): Promise<Experiences> {
    const url = extractLink(profile.links, 'experiences');
    const experiencesResource = await useUniversalFetch<ExperienceResource[]>(url);
    return new Experiences(experiencesResource);
}

async function fetchSectionsVisibility(profile: Profile, params: string | null = null): Promise<SectionsVisibility> {
    let url = extractLink(profile.links, 'sectionsVisibility');
    if (params !== null) {
        url += `?${params}`;
    }
    const sectionsVisibilityResource = await useUniversalFetch<SectionVisibilityResource>(url);
    return new SectionsVisibility(sectionsVisibilityResource);
}

async function fetchProfileExpertises(profile: Profile): Promise<ProfileExpertises> {
    const url = extractLink(profile.links, 'expertises');
    const profileExpertisesResource = await useUniversalFetch<ProfileExpertisesResource>(url);
    return new ProfileExpertises(profileExpertisesResource);
}

async function updateHeadline(profile: Profile, headline: string) {
    const url = extractLink(profile.links, 'updateHeadline');
    await useUniversalPost<void>(url, {body: {headline}});
}

async function updateDisplayOption(profile: Profile, option: string) {
    const url = extractLink(profile.links, 'updateDisplayOption');
    await useUniversalPost<void>(url, {body: {option}});
}

async function updateExperienceLevel(profile: Profile, experienceLevel: ExperienceLevel) {
    const url = extractLink(profile.links, 'updateExperienceLevel');
    await useUniversalPost<void>(url, {body: {experienceLevel}});
}

async function updatePrice(profile: Profile, request: UpdatePriceRequest) {
    const url = extractLink(profile.links, 'updatePrice');
    await useUniversalPost<void>(url, {body: request});
}

async function updateDescription(profile: Profile, description: string) {
    const url = extractLink(profile.links, 'updateDescription');
    await useUniversalPut<void>(url, {body: {description}});
}

async function uploadPhoto(profile: Profile, file: File): Promise<PhotoResource> {
    const fd = new FormData();
    fd.append('file', file);

    const linkUrl = extractLink(profile.links, 'uploadPhoto');
    const url = `${guestHost()}${linkUrl}`;
    return await useUniversalPost<PhotoResource>(`${url}`, {body: fd});
}

async function reloadPhoto(profile: Profile): Promise<Picture> {
    const url = extractLink(profile.links, 'getPhoto');
    return new Picture(await useUniversalFetch<PhotoResource>(url));
}

async function updateBreadcrumb(profile: Profile, breadcrumb: UpdateBreadcrumbRequest): Promise<void> {
    const url = extractLink(profile.links, 'updateBreadcrumb');
    await useUniversalPost<void>(url, {body: breadcrumb});
}

async function resendEmailValidation(profile: Profile): Promise<void> {
    const url = extractLink(profile.links, 'resendAccountValidationEmail');
    await useUniversalPost<void>(url);
}

async function updateLanguages(profile: Profile, request: UpdateLanguageRequest): Promise<void> {
    const url = extractLink(profile.links, 'updateLanguages');
    await useUniversalPost(url, {body: request});
}

async function updateEducations(profile: Profile, request: UpdateEducationRequest): Promise<void> {
    const updatedEducations = request.educations.map((education) => {
        if (education.current) {
            education.graduationYear = undefined;
        }

        return education;
    });
    const url = extractLink(profile.links, 'updateEducations');
    await useUniversalPost(url, {body: {educations: updatedEducations}});
}

async function updateCertifications(profile: Profile, request: UpdateCertificationRequest): Promise<void> {
    const updatedCertifications = request.certifications
        .map((certification) => {
            if (certification.current) {
                certification.graduationYear = undefined;
            }

            return certification;
        })
        .map((certification) => {
            return {
                id: certification.id,
                title: certification.title,
                organization: certification.organization,
                graduationYear: certification.graduationYear,
                link: certification.link,
                skills: certification.skills.map((skill) => skill.label),
            };
        });

    const url = extractLink(profile.links, 'updateCertifications');
    await useUniversalPost(url, {body: {certifications: updatedCertifications}});
}

async function autocompleteSkills(profile: Profile, query: string): Promise<Skill[]> {
    const url = extractLink(profile.links, 'autocompleteSkills');
    const skills: TagSuggestionResource[] = await useUniversalFetch<TagSuggestionResource[]>(urlService.buildUrl(url, query));

    return skills
        ? skills.map((skill) => {
              return {
                  label: skill.label,
                  tag: skill.tag,
                  occurences: skill.occurrences || 0,
              };
          })
        : [];
}

async function autocompleteCompanies(profile: Profile, query: string): Promise<CompanySuggestions> {
    const url = extractLink(profile.links, 'autocompleteCompanies');
    const companySuggestionResources = await useUniversalFetch<CompanySuggestionsResource>(urlService.buildUrl(url, query));
    return new CompanySuggestions(companySuggestionResources);
}

async function updateAppraisalVisibility(appraisal: Appraisal): Promise<void> {
    const url = extractLink(appraisal.links, 'updateVisibility');
    const updateUrl = `${guestHost()}${url}`;
    const updateAvailabilityUrl = urlService.buildUrl(updateUrl, appraisal.hidden);
    await useUniversalPost(updateAvailabilityUrl);
}

function updateActivityLocations(profile: Profile, payload: UpdateActivityLocationsRequest): Promise<UpdateActivityLocationsResource> {
    const url = extractLink(profile.links, 'updateActivityLocations');
    return useUniversalPut<UpdateActivityLocationsResource>(url, {body: payload});
}

function updateExperiences(profile: Profile, request: UpdateExperienceRequest): Promise<any> {
    const url = extractLink(profile.links, 'updateExperiences');
    return useUniversalPost<void>(url, {body: request});
}

function updateExperience(profile: Profile, request: ExperienceRequest): Promise<any> {
    const url = extractLink(profile.links, 'updateExperience');
    const urlWithId = urlService.buildUrl(url, request.id);
    return useUniversalPut<void>(urlWithId, {body: request});
}

function reorderExperiences(profile: Profile, request: string[]): Promise<any> {
    const url = extractLink(profile.links, 'reorderExperiences');
    return useUniversalPut<void>(url, {body: request});
}

function addExperience(profile: Profile, request: ExperienceRequest): Promise<any> {
    const url = extractLink(profile.links, 'addExperience');
    return useUniversalPost<void>(url, {body: request});
}

function deleteExperience(profile: Profile, experienceId: string): Promise<any> {
    const url = extractLink(profile.links, 'updateExperience');
    const urlWithId = urlService.buildUrl(url, experienceId);
    return useUniversalDelete<void>(urlWithId);
}

function updateSeoUrl(profile: Profile, payload: UpdateSeoUrlRequest): Promise<any> {
    const url = extractLink(profile.links, 'updateSeoUrl');
    return useUniversalPut<any>(url, {body: payload});
}

async function sendRecommendation(recommendations: Recommendations, recommendationRequest: RecommendationRequest): Promise<void> {
    const url = extractMaybeLink(recommendations.links, 'createRecommendation');

    if (url) {
        await useUniversalPost<any>(url, {body: recommendationRequest});
    }
}

function sendRecommendationRequest(profile: Profile, payload: InvitationsRequest): Promise<any> {
    const url = extractLink(profile.links, 'sendRecommendationRequests');
    return useUniversalPost<any>(url, {body: payload});
}

async function fetchFunctionalScopes(profile: Profile): Promise<FunctionalScopesAvailable> {
    const url = extractLink(profile.links, 'functionalScopesList');
    const functionalScopeResources = await useUniversalFetch<FunctionalScopeResource[]>(url);
    return new FunctionalScopesAvailable(functionalScopeResources);
}

function updateSkillSet(
    profile: Profile,
    selectedSkillsOrdered: ProfileSelectedSkillSetItem[],
    topSkillsOrdered: ProfileSelectedSkillSetItem[],
): Promise<void> {
    const url = extractLink(profile.links, 'updateSkills');
    const request = toUpdateSkillSetRequest(selectedSkillsOrdered, topSkillsOrdered);
    return useUniversalPut<void>(url, {body: request});
}

async function fetchIndustryExpertises(profile: Profile): Promise<IndustryExpertisesAvailable> {
    const url = extractLink(profile.links, 'industryExpertiseList');
    const industryExpertiseResource = await useUniversalFetch<IndustryExpertiseResource[]>(url);
    return new IndustryExpertisesAvailable(industryExpertiseResource);
}

async function fetchIndustryExpertisesRule(profile: Profile): Promise<MinMaxRuleResource> {
    const linkUrl = extractLink(profile.links, 'industryExpertiseRule');
    const url = `${linkUrl}?profileId=${profile.profileId}`;
    return await useUniversalFetch<MinMaxRuleResource>(url);
}

function updateIndustryExpertise(profile: Profile, payload: UpdateIndustryExpertiseRequest) {
    const url = extractLink(profile.links, 'updateIndustryExpertise');
    return useUniversalPut<any>(url, {body: payload});
}

async function fetchAveragePriceCurrentFreelancer(profile: Profile): Promise<PriceStat> {
    const url = extractLink(profile.links, 'getAveragePriceCurrentFreelancer');
    const priceStatResource = await useUniversalFetch<PriceStatResource>(url);
    return new PriceStat(priceStatResource);
}

export const profileRepository: ProfileRepository = {
    fetchProfile,
    fetchCompletionList,
    fetchAppraisals,
    fetchRelations,
    fetchMissionPreferences,
    fetchRecommendations,
    fetchBreadcrumb,
    fetchVerificationChecklist,
    fetchEducations,
    fetchFreelanceCharter,
    fetchDevDetails,
    fetchVisibility,
    fetchProfileAdmin,
    fetchSimilarProfiles,
    fetchTaskCards,
    fetchAdminEvents,
    fetchCertifications,
    fetchExperiences,
    fetchProfileExpertises,
    fetchFunctionalScopes,
    fetchIndustryExpertises,
    fetchIndustryExpertisesRule,
    fetchSectionsVisibility,
    fetchAveragePriceCurrentFreelancer,
    updateHeadline,
    reloadPhoto,
    resendEmailValidation,
    sendRecommendation,
    sendRecommendationRequest,
    updateDisplayOption,
    updateExperienceLevel,
    updatePrice,
    updateDescription,
    updateEducations,
    updateCertifications,
    updateAppraisalVisibility,
    updateActivityLocations,
    uploadPhoto,
    updateBreadcrumb,
    updateLanguages,
    autocompleteSkills,
    autocompleteCompanies,
    updateExperiences,
    updateExperience,
    reorderExperiences,
    addExperience,
    deleteExperience,
    updateSeoUrl,
    updateIndustryExpertise,
    updateSkillSet,
};

function toUpdateSkillSetRequest(
    selectedSkillsOrdered: ProfileSelectedSkillSetItem[],
    topSkillsOrdered: ProfileSelectedSkillSetItem[],
): UpdateProfileSkillsRequest {
    return {
        selectedSkillsOrder: selectedSkillsOrdered.map((selectedSkill) => toUpdateProfileSkillRequest(selectedSkill)),
        topSkills: topSkillsOrdered.map((topSkill) => toUpdateProfileSkillRequest(topSkill)),
    };
}

function toUpdateProfileSkillRequest(profileSelectedSkillSetItem: ProfileSelectedSkillSetItem): UpdateProfileSkillRequest {
    return {
        id: profileSelectedSkillSetItem.id,
        type: toUpdateProfileSkillTypeRequest(profileSelectedSkillSetItem.type),
    };
}

function toUpdateProfileSkillTypeRequest(selectedSkillType: SelectedSkillType): UpdateProfileSkillTypeRequest {
    switch (selectedSkillType) {
        case SelectedSkillType.FUNCTIONAL:
            return 'FUNCTIONAL';
        case SelectedSkillType.GLOBAL:
            return 'GLOBAL';
    }
}
