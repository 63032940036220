import type {MinMaxRuleResource, MinRuleResource} from '~/types/api-raw-types';

export class MinMaxRule {
    min: number;
    max: number;

    constructor(param: MinMaxRuleResource) {
        this.min = param.min;
        this.max = param.max;
    }

    isValid(current: number): boolean {
        return current >= this.min && current <= this.max;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class MinMaxDateRule {
    min: Date;
    max: Date;

    constructor(param: {min: Date; max: Date}) {
        this.min = param.min;
        this.max = param.max;
    }

    isValidMax(date: Date): boolean {
        return date <= this.max;
    }

    isValidMin(date: Date): boolean {
        return date >= this.min;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class MinRule {
    min: number;

    constructor(param: MinRuleResource) {
        this.min = param.min;
    }

    isValid(current: number): boolean {
        return current >= this.min;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
