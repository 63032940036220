/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProfileGlobalSkillRequest
 */
export interface UpdateProfileGlobalSkillRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileGlobalSkillRequest
     */
    label: string;
}

/**
 * Check if a given object implements the UpdateProfileGlobalSkillRequest interface.
 */
export function instanceOfUpdateProfileGlobalSkillRequest(value: object): value is UpdateProfileGlobalSkillRequest {
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function UpdateProfileGlobalSkillRequestFromJSON(json: any): UpdateProfileGlobalSkillRequest {
    return UpdateProfileGlobalSkillRequestFromJSONTyped(json, false);
}

export function UpdateProfileGlobalSkillRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileGlobalSkillRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'label': json['label'],
    };
}

export function UpdateProfileGlobalSkillRequestToJSON(value?: UpdateProfileGlobalSkillRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'label': value['label'],
    };
}

