import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import {PortfolioHelper} from '~/helpers/portfolio/PortfolioHelper';
import type {PortfolioPictureResource, PortfolioResource, PortfolioWithItemsResource, ResponsivePictureResource} from '~/types/api-raw-types';

export enum PortfolioItemType {
    PHOTO = 'PHOTO',
    VIDEO = 'VIDEO',
}

export class PortfolioItemThumbnail {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
export class PortfolioItemThumbnails {
    highResolution: PortfolioItemThumbnail;
    mediumResolution: PortfolioItemThumbnail;
    lowResolution: PortfolioItemThumbnail;

    constructor(responsivePictureResource: ResponsivePictureResource) {
        this.highResolution = new PortfolioItemThumbnail(responsivePictureResource.highResolutionUrl);
        this.mediumResolution = new PortfolioItemThumbnail(responsivePictureResource.mediumResolutionUrl);
        this.lowResolution = new PortfolioItemThumbnail(responsivePictureResource.lowResolutionUrl);
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
export class PortfolioItem {
    id: string;
    index: number;
    type: PortfolioItemType;
    title: string;
    url: string;
    thumbnails: PortfolioItemThumbnails;

    constructor(portfolioPhotoResource: PortfolioPictureResource) {
        this.id = portfolioPhotoResource.id;
        this.index = portfolioPhotoResource.index;
        this.type = portfolioPhotoResource.type === 'VIDEO' ? PortfolioItemType.VIDEO : PortfolioItemType.PHOTO;
        this.title = portfolioPhotoResource.title;
        this.url =
            this.type === PortfolioItemType.VIDEO ? PortfolioHelper.getEmbededVideoURL(portfolioPhotoResource.url) : portfolioPhotoResource.url;
        this.thumbnails = new PortfolioItemThumbnails(portfolioPhotoResource.picture);
    }

    get isVideo(): boolean {
        return this.type === PortfolioItemType.VIDEO;
    }

    get portfolioImageMaxImageUrl(): string {
        console.log('max image:' + this.thumbnails.highResolution.url);
        const url = new URL(this.thumbnails.highResolution.url);
        if (this.isVideo) {
            url.searchParams.set('org_if_sml', '1');

            if (this.id > '67ec61e00000000000000000') {
                // this ensures we never cache the same video thumbnail if the portfolio item is recreated
                // however I don't want all old thumbnails to be refreshed in the filerobot cache,
                // so we consider only new items
                url.searchParams.set('v_id', this.id);
            }
        }
        return url.toString();
    }

    toJSON() {
        return {
            ...this,
            // we need this because the class is actually passed as a JSON object on client side
            portfolioImageMaxImageUrl: this.portfolioImageMaxImageUrl,
            isVideo: this.isVideo,
        };
    }
}

export class Portfolio extends AbstractResource {
    items: PortfolioItem[] = [];
    override links: any;

    constructor(params: PortfolioResource) {
        super(params);
        this.links = params._links;
    }

    updateItems(portfolioWithItemsResource: PortfolioWithItemsResource) {
        this.items = portfolioWithItemsResource.items.map((item) => new PortfolioItem(item));
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static empty() {
        return new Portfolio({_links: [], itemsCount: 0});
    }
}
