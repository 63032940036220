import {definePayloadPlugin, definePayloadReducer, definePayloadReviver} from '#imports';
import type {NuxtPayloadValueContainingAndUrl} from '~/helpers/payload/NuxtPayloadUrlEncoder';
import {NuxtPayloadUrlEncoder} from '~/helpers/payload/NuxtPayloadUrlEncoder';

/**
 * This plugin defines the payload handlers to:
 * - base 64 encode URL during serialization
 * - base 64 decode URL during deserialization
 * By doing this we obfuscate the URL in the payload and avoid bots from crawling it.
 */
function definePayloadHandlers() {
    definePayloadReducer('payload-urls', (value: any): NuxtPayloadValueContainingAndUrl | undefined => {
        if (typeof value !== 'string') {
            return;
        }
        return NuxtPayloadUrlEncoder.encode(value);
    });
    definePayloadReviver('payload-urls', (value: NuxtPayloadValueContainingAndUrl) => {
        return NuxtPayloadUrlEncoder.decode(value);
    });
}

export default definePayloadPlugin(() => {
    definePayloadHandlers();
});
