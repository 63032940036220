import type {TranslationRepository} from '~/types/domain/repository/TranslationRepository';
import type {Profile} from '~/types/domain/Profile';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import {useUniversalPost} from '#imports';

async function translate(toLang: string, content: string[], profile: Profile): Promise<string[]> {
    try {
        const url = extractLink(profile.links, 'translate');
        const request = {
            fromLanguage: profile.dominantLanguageDetected,
            toLanguage: toLang,
            contents: content,
        };
        return await useUniversalPost<string[]>(url, {body: request});
    } catch (error) {
        return Promise.reject(error);
    }
}

export const translationRepository: TranslationRepository = {
    translate,
};
