import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import type {DateAsString, ProfileAdminResource} from '~/types/api-raw-types';
export class ProfileAdmin extends AbstractResource {
    accountCreationDate: DateAsString;
    accountId: string;
    companyId: string;
    defaultHost: string;
    email: string;
    identityId: string;
    lastLogin: DateAsString;
    lastModification: DateAsString;
    locale: string;
    phoneNumber: string;
    profileCreationDate: DateAsString;
    profileId: string;
    override links: any;

    constructor(params: ProfileAdminResource) {
        super(params);
        this.accountCreationDate = params.accountCreationDate;
        this.accountId = params.accountId;
        this.companyId = params.companyId;
        this.defaultHost = params.defaultHost;
        this.email = params.email;
        this.identityId = params.identityId;
        this.lastLogin = params.lastLogin;
        this.lastModification = params.lastModification;
        this.locale = params.locale;
        this.phoneNumber = params.phoneNumber;
        this.profileCreationDate = params.profileCreationDate;
        this.profileId = params.profileId;
        this.links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static defaultProfileAdmin(): ProfileAdmin {
        return new ProfileAdmin({
            accountCreationDate: '',
            accountId: '',
            companyId: '',
            defaultHost: '',
            email: '',
            identityId: '',
            lastLogin: '',
            lastModification: '',
            locale: '',
            phoneNumber: '',
            profileCreationDate: '',
            profileId: '',
            _links: [],
        });
    }
}
