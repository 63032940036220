import {groupBy} from 'lodash-es';
import type {ExtendedLink, ExtendedResourceRegistryEntries, LinkEntry, RegistryEntryContext, ResourceRegistryEntries} from '../registry/registry';

function safeUriDecode(uri: string): string {
    try {
        return decodeURIComponent(uri);
    } catch (err) {
        return '';
    }
}

function getSingleLink(linksCollection: LinkEntry[]): () => string {
    return () => safeUriDecode(linksCollection[0].href);
}

function getExtendedLink(linksCollection: LinkEntry[]): () => ExtendedLink {
    return () => {
        return {
            url: safeUriDecode(linksCollection[0].href),
            method: linksCollection[0].type!,
        };
    };
}

function generateLinkGetterByContext(linksCollection: LinkEntry[]) {
    return (context: RegistryEntryContext) => {
        if (!context) {
            const linkItemWithoutContext = linksCollection.find((r) => !r.context.length);

            return linkItemWithoutContext ? linkItemWithoutContext.href : '';
        }

        const contextKey = context[0];
        const contextValue = context[1];
        const tmp = linksCollection.find((r) => {
            return !!r.context.find((c) => {
                return c.rel === contextKey && c.href === contextValue;
            });
        });

        return tmp ? safeUriDecode(tmp.href) : '';
    };
}

function hasMultipleLinksOrContext(linksCollection: LinkEntry[]): boolean {
    return linksCollection.length > 1 || (!!linksCollection[0].context && !!linksCollection[0].context.length);
}

function generateLinkGetter(linksCollection: LinkEntry[]) {
    return hasMultipleLinksOrContext(linksCollection) ? generateLinkGetterByContext(linksCollection) : getSingleLink(linksCollection);
}

function groupByRel(_links: LinkEntry[]): any {
    return groupBy(_links, 'rel');
}

export function buildRegistry(links: LinkEntry[]): ResourceRegistryEntries {
    const linksObj = groupByRel(links);
    Object.keys(linksObj).forEach((k) => (linksObj[k] = generateLinkGetter(linksObj[k])));
    return linksObj;
}

export function buildExtendedRegistry(links: LinkEntry[]): ExtendedResourceRegistryEntries {
    const linksObj = groupByRel(links);
    Object.keys(linksObj).forEach((k) => (linksObj[k] = getExtendedLink(linksObj[k])));
    return linksObj;
}
