import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import type {DevDetailsResource} from '~/types/api-raw-types';
export class DevDetails extends AbstractResource {
    accountId: string;
    companyId: string;
    identityId: string;
    profileId: string;
    override links: any;

    constructor(params: DevDetailsResource) {
        super(params);
        this.accountId = params.accountId;
        this.companyId = params.companyId;
        this.identityId = params.identityId;
        this.profileId = params.profileId;
        this.links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
