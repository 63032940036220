import type {MissionPreferenceItemResource, MissionPreferencesResource} from '~/types/api-raw-types';

export class MissionPreference {
    operator: 'POSITIVE' | 'NEGATIVE';
    value: string;

    constructor(missionPreferenceResource: MissionPreferenceItemResource) {
        if (missionPreferenceResource.operator.toUpperCase() === 'POSITIVE') {
            this.operator = missionPreferenceResource.operator = 'POSITIVE';
        } else {
            this.operator = missionPreferenceResource.operator = 'NEGATIVE';
        }

        this.value = missionPreferenceResource.value;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class MissionPreferences {
    activityArea: MissionPreference[] = [];
    companySize: MissionPreference[] = [];
    projectDuration: MissionPreference[] = [];

    constructor(resource: MissionPreferencesResource) {
        resource.items.forEach((missionPreferenceResource) => {
            // necessary to prevent a typing error from api-raw-types
            const missionPreferenceResourceCast = missionPreferenceResource as MissionPreferenceItemResource;
            const missionPreference = new MissionPreference(missionPreferenceResourceCast);

            switch (missionPreferenceResourceCast.type.toUpperCase()) {
                case 'ACTIVITY_AREA':
                    this.activityArea.push(missionPreference);
                    break;
                case 'COMPANY_SIZE':
                    this.companySize.push(missionPreference);
                    break;
                case 'DURATION':
                    this.projectDuration.push(missionPreference);
                    break;
            }
        });
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
