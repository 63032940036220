import type {ExtendedResource, ExtendedResourceParams, Resource, ResourceId, ResourceParams, ResourceRef} from './resource';
import {ResourceRegistry} from '../registry/ResourceRegistry';
import type {ExtendedRegistryEntry, LinkEntry, RegistryEntry} from '../registry/registry';
import type {Dictionary} from '../common';

let idx = 1;

function uniqId(): string {
    return `resource_${idx++}`;
}

export interface RawResource {
    _links: LinkEntry[];
}

export abstract class AbstractResource implements Resource {
    readonly id: ResourceId;
    readonly ref: ResourceRef;
    links: Dictionary<RegistryEntry>;

    protected constructor(resource: RawResource | ResourceParams) {
        if ('links' in resource) {
            this.links = resource.links;
        } else {
            this.links = new ResourceRegistry(resource._links, false).entries;
        }

        this.ref = this.links.self ? this.links.self() : '';
        this.id = this.ref ? this.ref.replace(/\//g, '') : uniqId();
    }
}

export abstract class AbstractExtendedResource implements ExtendedResource {
    readonly id: ResourceId;
    readonly ref: ResourceRef;
    links: Dictionary<ExtendedRegistryEntry>;

    protected constructor(resource: RawResource | ExtendedResourceParams) {
        if ('links' in resource) {
            this.links = resource.links;
        } else {
            this.links = new ResourceRegistry(resource._links, true).extendedEntries;
        }
        this.ref = this.links.self ? this.links.self().url : '';
        this.id = this.ref ? this.ref.replace(/\//g, '') : uniqId();
    }
}
