/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { UpdateProfileFunctionalSkillRequest } from './';
import {
    UpdateProfileFunctionalSkillRequestFromJSON,
    UpdateProfileFunctionalSkillRequestFromJSONTyped,
    UpdateProfileFunctionalSkillRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProfileFunctionalSkillsRequest
 */
export interface UpdateProfileFunctionalSkillsRequest {
    /**
     * 
     * @type {Array<UpdateProfileFunctionalSkillRequest>}
     * @memberof UpdateProfileFunctionalSkillsRequest
     */
    functionalSkills: Array<UpdateProfileFunctionalSkillRequest>;
}

/**
 * Check if a given object implements the UpdateProfileFunctionalSkillsRequest interface.
 */
export function instanceOfUpdateProfileFunctionalSkillsRequest(value: object): value is UpdateProfileFunctionalSkillsRequest {
    if (!('functionalSkills' in value) || value['functionalSkills'] === undefined) return false;
    return true;
}

export function UpdateProfileFunctionalSkillsRequestFromJSON(json: any): UpdateProfileFunctionalSkillsRequest {
    return UpdateProfileFunctionalSkillsRequestFromJSONTyped(json, false);
}

export function UpdateProfileFunctionalSkillsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileFunctionalSkillsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'functionalSkills': ((json['functionalSkills'] as Array<any>).map(UpdateProfileFunctionalSkillRequestFromJSON)),
    };
}

export function UpdateProfileFunctionalSkillsRequestToJSON(value?: UpdateProfileFunctionalSkillsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'functionalSkills': ((value['functionalSkills'] as Array<any>).map((v) => UpdateProfileFunctionalSkillRequestToJSON(v))),
    };
}

