import type {FetchResponse} from 'ofetch';
import {defineNuxtPlugin, reactive, readonly, useLogger, useRequestEvent} from '#imports';
import {type ApiHeader, isAllowedCookie} from '../utils/api-cookie';
import {appendResponseHeader} from 'h3';

/**
 * The goal of this plugin is to append `Set-Cookie` headers returned by server side API calls to the Nuxt server
 * side response (typically the session cookie for users visiting the website for the first time).
 *
 * It also allows to retrieve such cookies so that they can be reused in subsequent server-side API calls during
 * the rendering of the same page, preventing to create multiple sessions in parallel.
 */
export default defineNuxtPlugin({
    name: 'backend-api-cookies',
    dependsOn: ['logger'],
    setup(): {
        provide: {
            apiCookies: {
                cookieValues: Readonly<Partial<Record<ApiHeader, string>>>;
                registerServerSideSetCookies: <T>(response: FetchResponse<T>) => void;
            };
        };
    } {
        const logger = useLogger();

        if (!import.meta.server) {
            // even if it will not be used we need this plugin to exist on the client side,
            // so that plugin dependency declarations don't log an error
            return {
                provide: {
                    apiCookies: {
                        cookieValues: readonly({}),
                        registerServerSideSetCookies: () => {
                            logger.warn(new Error('Not implemented on client side'));
                        },
                    },
                },
            };
        }

        const cookieValues: Partial<Record<ApiHeader, string>> = reactive({});
        const event = useRequestEvent()!;

        function registerServerSideSetCookies<T>(response: FetchResponse<T>): void {
            response.headers.getSetCookie().forEach((header) => {
                const cookieParts: string[] = header.split('=');
                if (cookieParts.length < 2) {
                    return;
                }

                const cookieName = cookieParts[0].trim();

                if (isAllowedCookie(cookieName) && !cookieValues[cookieName]) {
                    const valueParts = cookieParts[1].split(';');

                    if (!valueParts.length) {
                        return;
                    }

                    cookieValues[cookieName] = valueParts[0].trim();

                    // See https://nuxt.com/docs/getting-started/data-fetching#pass-cookies-from-server-side-api-calls-on-ssr-response
                    appendResponseHeader(event, 'set-cookie', header);
                }
            });
        }

        return {
            provide: {
                apiCookies: {
                    cookieValues: readonly(cookieValues),
                    registerServerSideSetCookies,
                },
            },
        };
    },
});
