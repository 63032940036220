import {defineNuxtPlugin} from '#imports';
import DOMPurify from 'dompurify';

export default defineNuxtPlugin(() => {
    const sanitize = DOMPurify.sanitize as (s: string) => string;

    return {
        provide: {
            sanitizeHtml: sanitize,
        },
    };
});
