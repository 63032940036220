import type {
    ExpertiseLevelResource,
    ProfileExpertisesResource,
    ProfileFunctionalSkillResource,
    ProfileGlobalSkillResource,
    ProfileIndustryExpertiseResource,
    ProfileSkillResource,
    ProfileSkillTypeResource,
} from '~/types/api-raw-types';

export enum ExpertiseLevel {
    NONE,
    BEGINNER,
    INTERMEDIATE,
    ADVANCED,
}

export enum SelectedSkillType {
    GLOBAL,
    FUNCTIONAL,
}

export class ProfileGlobalSkill {
    label: string;
    translatedLabel?: string;
    level: ExpertiseLevel;
    preferred: boolean;
    seoUrl?: string;

    constructor(params: ProfileGlobalSkillResource) {
        this.label = params.label;
        this.translatedLabel = params.translatedLabel;
        this.level = toExpertiseLevel(params.level);
        this.preferred = params.preferred;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class ProfileFunctionalSkill {
    id: string;
    label: string;
    level: ExpertiseLevel;
    preferred: boolean;
    seoUrl?: string;

    constructor(params: ProfileFunctionalSkillResource) {
        this.id = params.id;
        this.label = params.label;
        this.level = toExpertiseLevel(params.level);
        this.preferred = params.preferred;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class ProfileIndustryExpertise {
    id: string;
    label: string;
    seoUrl?: string;

    constructor(params: ProfileIndustryExpertiseResource) {
        this.id = params.id;
        this.label = params.label;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export type ProfileSelectedSkillSetItem = {
    type: SelectedSkillType;
    id: string;
    label: string;
    seoUrl?: string;
    translatedLabel?: string;
};

export class ProfileExpertises {
    globalSkills: ProfileGlobalSkill[];
    functionalSkills: ProfileFunctionalSkill[];
    industryExpertises: ProfileIndustryExpertise[];

    selectedSkills: ProfileSelectedSkillSetItem[];
    topSkills: ProfileSelectedSkillSetItem[];

    constructor(params: ProfileExpertisesResource) {
        this.globalSkills = params.globalSkills.map((globalSkill) => new ProfileGlobalSkill(globalSkill));
        this.functionalSkills = params.functionalSkills.map((functionalSkill) => new ProfileFunctionalSkill(functionalSkill));
        this.industryExpertises = params.industryExpertises.map((industryExpertise) => new ProfileIndustryExpertise(industryExpertise));
        this.selectedSkills = params.selectedSkillsOrder.map((selectedSkill) => toProfileSelectedSkillSetItem(selectedSkill));
        this.topSkills = params.topSkills.map((topSkill) => toProfileSelectedSkillSetItem(topSkill));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

function toExpertiseLevel(levelResource: ExpertiseLevelResource): ExpertiseLevel {
    switch (levelResource) {
        case 'NONE':
            return ExpertiseLevel.NONE;
        case 'BEGINNER':
            return ExpertiseLevel.BEGINNER;
        case 'INTERMEDIATE':
            return ExpertiseLevel.INTERMEDIATE;
        case 'ADVANCED':
            return ExpertiseLevel.ADVANCED;
    }
    return ExpertiseLevel.NONE;
}

function toSelectedSkillType(skillTypeResource: ProfileSkillTypeResource): SelectedSkillType {
    switch (skillTypeResource) {
        case 'FUNCTIONAL':
            return SelectedSkillType.FUNCTIONAL;
        case 'GLOBAL':
            return SelectedSkillType.GLOBAL;
    }
}

function toProfileSelectedSkillSetItem(skillResource: ProfileSkillResource): ProfileSelectedSkillSetItem {
    return {
        type: toSelectedSkillType(skillResource.type),
        id: skillResource.id,
        label: skillResource.label,
        seoUrl: skillResource.seoUrl,
        translatedLabel: skillResource.translatedLabel,
    };
}
