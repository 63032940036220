import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import type {ProfileTagSuggestionResource, ProfileTagSuggestionsResource} from '@/types/api-raw-types';
import {useUniversalPost} from '#imports';

export class TagSuggestion extends AbstractResource {
    label: string;
    position: number;
    recommendationId?: string;

    constructor(params: ProfileTagSuggestionResource) {
        super(params);
        this.label = params.label;
        this.position = params.position;
        if (params.recommendationId) {
            this.recommendationId = params.recommendationId;
        }
    }

    addToProfile(): Promise<void> {
        return useUniversalPost<void>(this.links.addTag(), {
            body: {
                tag: this.label,
                position: this.position,
                tagRecoId: this.recommendationId,
            },
        });
    }

    registerWebPreferenceTag(): Promise<void> {
        return useUniversalPost<void>(this.links.registerWebPreferenceTag());
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class TagsSuggestions extends AbstractResource {
    tags: TagSuggestion[];

    constructor(params: ProfileTagSuggestionsResource) {
        super(params);
        this.tags = params.tags.map((item) => new TagSuggestion(item));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
