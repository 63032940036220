<template>
    <VJoyWrapper class="profile-gone" justify="center" direction="column">
        <MaltImg src="/profile/soucoupe" width="70" alt="" />
        <div class="profile-gone-text">
            {{ t('error.profile.gone') }}
        </div>
        <VJoyButton variant="main" @click="handleError">{{ ctaLabel }}</VJoyButton>
    </VJoyWrapper>
</template>

<script setup lang="ts">
    import type {NuxtError} from '#app';
    import {MaltImg} from '#components';
    import {clearError, computed, useTranslation} from '#imports';
    import {navigateTo} from '@typed-router';
    import {VJoyButton, VJoyWrapper} from '@maltjoy/core-vue';

    const {t} = useTranslation();

    const props = defineProps<{
        error: NuxtError;
    }>();

    const ctaLabel = computed<string>(() => {
        if (typeof props.error?.data === 'string') {
            try {
                const label = JSON.parse(props.error?.data)?.familyOrCategoryLabel;

                if (label) {
                    return t('error.profile.gone.viewmore.cta', {0: label});
                }
            } catch (e) {
                // ignored on purpose, we are already in an error rendering page
                // the original error has been reported no need to crash the error page
                // on unexpected inputs
            }
        }

        return t('error.profile.gone.viewmore.nofamily.cta');
    });

    function handleError() {
        const redirectUrl = props.error?.data ? JSON.parse(props.error?.data as string).viewMoreUrl : '/';

        clearError();
        navigateTo(redirectUrl, {external: true});
    }
</script>

<style lang="scss" scoped>
    .profile-gone {
        min-height: 760px;

        &-text {
            font-size: var(--joy-font-size-primary-600);
        }
    }
</style>
