<template>
    <Navbar />
    <VJoyTemplate>
        <template #template-main>
            <ProfileGone v-if="error.statusCode === 410 || error.statusCode === 404" :error="error" />
            <ErrorPage v-else :error="error" />
        </template>
    </VJoyTemplate>
    <FooterUnlogged />
</template>

<script setup lang="ts">
    import type {NuxtError} from '#app';
    import {ErrorPage, FooterUnlogged, Navbar} from '#components';
    import {VJoyTemplate} from '@maltjoy/core-vue';
    import {useServerSeoMeta, useTranslation} from '#imports';
    import ProfileGone from '~/components/ProfileGone.vue';

    const {t} = useTranslation();

    defineProps<{
        error: NuxtError;
    }>();

    useServerSeoMeta({
        title: t('400.title.page'),
    });
</script>

<style lang="scss" scoped>
    .profile-gone {
        font-size: var(--joy-font-size-primary-600);
    }
</style>
