import type {CompanySuggestionResource, CompanySuggestionsResource} from '~/types/api-raw-types';

export class CompanySuggestion {
    key: string;
    text: string;
    others: {[index: string]: string};

    constructor(params: CompanySuggestionResource) {
        this.key = params.key;
        this.text = params.text;
        this.others = params.others;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class CompanySuggestions {
    items: CompanySuggestion[];

    constructor(params: CompanySuggestionsResource) {
        this.items = params.suggestions.map((suggestion) => new CompanySuggestion(suggestion));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
