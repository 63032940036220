// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {definePreset} from '@primevue/themes';
import Aura from '@primevue/themes/aura';

export const MaltPreset = definePreset(Aura, {
    components: {
        autocomplete: {
            list: {
                padding: 'none',
            },
            overlay: {
                shadow: 'var(--joy-core-elevation-3)',
                borderColor: 'var(--joy-color-secondary-30)',
                borderRadius: 'var(--joy-core-radius-3)',
            },
            dropdown: {
                hoverBackground: 'var(--joy-color-secondary-10)',
            },
            option: {
                padding: '10px 15px',
                color: 'var(--joy-color-text-body)',
                focusBackground: 'var(--joy-color-secondary-10)',
                focusColor: 'inherit',
                selectedBackground: 'var(--joy-color-secondary-10)',
                selectedColor: 'inherit',
                selectedFocusBackground: 'var(--joy-color-secondary-10)',
                selectedFocusColor: 'inherit',
            },
        },
        multiselect: {
            list: {
                padding: 'none',
            },
            overlay: {
                shadow: 'var(--joy-core-elevation-3)',
                borderColor: 'var(--joy-color-secondary-30)',
                borderRadius: 'var(--joy-core-radius-3)',
            },
            option: {
                padding: 'var(--joy-core-spacing-4) var(--joy-core-spacing-5)',
                color: 'var(--joy-color-text-body)',
                focusBackground: 'var(--joy-color-secondary-10)',
                focusColor: 'inherit',
            },
        },
        datepicker: {
            date: {
                selected: {
                    background: 'var(--joy-color-secondary-30)',
                    color: 'white',
                },
            },
        },
        datatable: {
            row: {
                selected: {
                    background: 'var(--joy-color-tertiary-10)',
                    color: 'inherit',
                },
            },
        },
    },
});

export default {
    preset: MaltPreset,
    options: {
        darkModeSelector: '.joy-app-dark',
    },
};
