import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';
import {Languages} from '~/types/domain/Languages';
import {Price} from '~/types/domain/Price';
import type {
    AddressResource,
    AvailabilityResource,
    BadgeVisibilityValueResource,
    ConversationStatisticsResource,
    PhotoResource,
    PrivacyResource,
    ProfileResource,
    RecommendationResource,
} from '~/types/api-raw-types';
import {Categorization} from '~/types/domain/Categorization';
import {Portfolio} from '~/types/domain/Portfolio';
import {LocationPreferences} from './LocationPreferences';

export enum ExperienceLevel {
    ENTRY = 'ENTRY',
    INTERMEDIATE = 'INTERMEDIATE',
    EXPERT = 'EXPERT',
    EXPERT_PLUS = 'EXPERT_PLUS',
}

export class Picture {
    baseUrl: string;
    cropped: boolean;
    height: number;
    legend: string;
    name: string;
    originalExtension: string;
    originalMimeType: string;
    type: string;
    width: number;

    constructor(params: PhotoResource) {
        this.baseUrl = params.baseUrl;
        this.cropped = params.cropped;
        this.height = params.height;
        this.legend = params.legend;
        this.name = params.name;
        this.originalExtension = params.originalExtension;
        this.originalMimeType = params.originalMimeType;
        this.type = params.type;
        this.width = params.width;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export enum ProfileVertical {
    FREELANCER = 'FREELANCER',
    STRATEGY = 'STRATEGY',
}

export class Profile extends AbstractResource {
    accountId: string;
    address: AddressResource;
    availability: AvailabilityResource;
    badge: BadgeVisibilityValueResource;
    categorization: Categorization;
    conversationStatistics: ConversationStatisticsResource;
    description: string;
    dominantLanguageDetected: string;
    experienceLevel: ExperienceLevel;
    firstname: string;
    lastname: string;
    headline: string;
    key: string;
    languages: Languages;
    portfolio: Portfolio;
    price: Price;
    photo?: Picture | null;
    locationPreferences: LocationPreferences;
    privacy: PrivacyResource;
    recommendations: RecommendationResource[] = [];
    seoAlias: string;
    profileId: string;
    profileUrl: string;
    profileCanonicalUrl: string;
    profileViewId: string;
    vertical: ProfileVertical;

    editMode: boolean;
    override links: any;

    constructor(params: ProfileResource) {
        super(params);

        this.accountId = params.accountId;
        this.address = params.address;
        this.availability = params.availability;
        this.badge = params.badge;
        this.categorization = new Categorization(params.categorization);
        this.conversationStatistics = params.conversationStatistics;
        this.description = params.description;
        this.dominantLanguageDetected = params.dominantLanguageDetected;
        this.experienceLevel = ExperienceLevel[params.experienceLevel];
        this.firstname = params.firstname;
        this.lastname = params.lastname;
        this.headline = params.headline;
        this.key = params.key;
        this.languages = new Languages(params.languages);
        this.portfolio = new Portfolio(params.portfolio);
        this.price = new Price(params.price);
        this.photo = params.photo ? new Picture(params.photo) : null;
        this.locationPreferences = new LocationPreferences(params.missionPreference);
        this.privacy = params.privacy;
        this.seoAlias = params.seoAlias;
        this.profileId = params.profileId;
        this.profileUrl = params.profileUrl;
        this.profileCanonicalUrl = params.profileCanonicalUrl;
        this.profileViewId = params.profileViewId;
        this.editMode = params.editMode;
        this.links = params._links;
        this.vertical = ProfileVertical[params.vertical];
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
