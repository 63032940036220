
/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  SimpleError,
  UpdateIndustryExpertisesRequest,
  UpdateProfileFunctionalSkillsRequest,
  UpdateProfileGlobalSkillsRequest,
  UpdateProfileSkillsRequest,
} from '../models';
import {
    SimpleErrorFromJSON,
    SimpleErrorToJSON,
    UpdateIndustryExpertisesRequestFromJSON,
    UpdateIndustryExpertisesRequestToJSON,
    UpdateProfileFunctionalSkillsRequestFromJSON,
    UpdateProfileFunctionalSkillsRequestToJSON,
    UpdateProfileGlobalSkillsRequestFromJSON,
    UpdateProfileGlobalSkillsRequestToJSON,
    UpdateProfileSkillsRequestFromJSON,
    UpdateProfileSkillsRequestToJSON,
} from '../models';

export interface UpdateFunctionalSkillsRequest {
    updateProfileFunctionalSkillsRequest: UpdateProfileFunctionalSkillsRequest;
}

export interface UpdateGlobalSkillsRequest {
    updateProfileGlobalSkillsRequest: UpdateProfileGlobalSkillsRequest;
}

export interface UpdateIndustryExpertisesOperationRequest {
    updateIndustryExpertisesRequest: UpdateIndustryExpertisesRequest;
}

export interface UpdateSkillsRequest {
    updateProfileSkillsRequest: UpdateProfileSkillsRequest;
}

/**
 * 
 */
export class ExpertiseApi extends runtime.BaseAPI {

    /**
     * Update functional skills
     */
    async updateFunctionalSkillsRaw(requestParameters: UpdateFunctionalSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<object>> {
        if (requestParameters['updateProfileFunctionalSkillsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProfileFunctionalSkillsRequest',
                'Required parameter "updateProfileFunctionalSkillsRequest" was null or undefined when calling updateFunctionalSkills().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<object>({
            path: `/profile/api/expertises/functional-skills`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileFunctionalSkillsRequestToJSON(requestParameters['updateProfileFunctionalSkillsRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Update functional skills
     */
    async updateFunctionalSkills(requestParameters: UpdateFunctionalSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateFunctionalSkillsRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     * Update global skills
     */
    async updateGlobalSkillsRaw(requestParameters: UpdateGlobalSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<object>> {
        if (requestParameters['updateProfileGlobalSkillsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProfileGlobalSkillsRequest',
                'Required parameter "updateProfileGlobalSkillsRequest" was null or undefined when calling updateGlobalSkills().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<object>({
            path: `/profile/api/expertises/global-skills`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileGlobalSkillsRequestToJSON(requestParameters['updateProfileGlobalSkillsRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Update global skills
     */
    async updateGlobalSkills(requestParameters: UpdateGlobalSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateGlobalSkillsRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     * Update industry expertises
     */
    async updateIndustryExpertisesRaw(requestParameters: UpdateIndustryExpertisesOperationRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<object>> {
        if (requestParameters['updateIndustryExpertisesRequest'] == null) {
            throw new runtime.RequiredError(
                'updateIndustryExpertisesRequest',
                'Required parameter "updateIndustryExpertisesRequest" was null or undefined when calling updateIndustryExpertises().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<object>({
            path: `/profile/api/expertises/industry`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIndustryExpertisesRequestToJSON(requestParameters['updateIndustryExpertisesRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Update industry expertises
     */
    async updateIndustryExpertises(requestParameters: UpdateIndustryExpertisesOperationRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateIndustryExpertisesRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     * Update skills
     */
    async updateSkillsRaw(requestParameters: UpdateSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<object>> {
        if (requestParameters['updateProfileSkillsRequest'] == null) {
            throw new runtime.RequiredError(
                'updateProfileSkillsRequest',
                'Required parameter "updateProfileSkillsRequest" was null or undefined when calling updateSkills().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<object>({
            path: `/profile/api/expertises/skills`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileSkillsRequestToJSON(requestParameters['updateProfileSkillsRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Update skills
     */
    async updateSkills(requestParameters: UpdateSkillsRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateSkillsRaw(requestParameters, initOverrides);
        return response._data;
    }

}
