export const PortfolioHelper = {
    getYoutubeId(url: string) {
        const regExp = /^.*((youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    },

    getVimeoId(url: string) {
        const regExp = /^.*(?:vimeo\.com)\/(?:channels\/|channels\/\w+\/|groups\/[^/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
        const match = url.match(regExp);
        return match && /^\d+$/.test(match[1]) ? match[1] : false;
    },

    getVideoType(url: string): 'VIMEO' | 'YOUTUBE' | undefined {
        if (url.includes('vimeo')) {
            return 'VIMEO';
        }

        if (url.includes('youtube') || url.includes('youtu.be')) {
            return 'YOUTUBE';
        }
    },
    getEmbededVideoURL(url: string): string {
        switch (this.getVideoType(url)) {
            case 'VIMEO':
                return 'https://player.vimeo.com/video/' + this.getVimeoId(url);
            case 'YOUTUBE':
                return 'https://youtube.com/embed/' + this.getYoutubeId(url);
            default:
                return url;
        }
    },
};
