/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateIndustryExpertisesRequest
 */
export interface UpdateIndustryExpertisesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateIndustryExpertisesRequest
     */
    expertises: Array<string>;
}

/**
 * Check if a given object implements the UpdateIndustryExpertisesRequest interface.
 */
export function instanceOfUpdateIndustryExpertisesRequest(value: object): value is UpdateIndustryExpertisesRequest {
    if (!('expertises' in value) || value['expertises'] === undefined) return false;
    return true;
}

export function UpdateIndustryExpertisesRequestFromJSON(json: any): UpdateIndustryExpertisesRequest {
    return UpdateIndustryExpertisesRequestFromJSONTyped(json, false);
}

export function UpdateIndustryExpertisesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIndustryExpertisesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'expertises': json['expertises'],
    };
}

export function UpdateIndustryExpertisesRequestToJSON(value?: UpdateIndustryExpertisesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expertises': value['expertises'],
    };
}

