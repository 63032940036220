export enum FreelancerSignupStepName {
    WELCOME_TO_MALT_REGULAR = 'WELCOME_TO_MALT_REGULAR',
    WELCOME_TO_MALT_OPEN = 'WELCOME_TO_MALT_OPEN',
    WELCOME_TO_MALT_STRATEGY = 'WELCOME_TO_MALT_STRATEGY',

    FREELANCING_EXPERIENCE = 'FREELANCING_EXPERIENCE',
    DESCRIBE_YOURSELF = 'DESCRIBE_YOURSELF',
    CREATE_YOUR_ACCOUNT = 'CREATE_YOUR_ACCOUNT',
    VERIFY_YOUR_EMAIL = 'VERIFY_YOUR_EMAIL',
    COMPLETE_YOUR_ACCOUNT = 'COMPLETE_YOUR_ACCOUNT',

    SHOWCASE_YOUR_TALENTS = 'SHOWCASE_YOUR_TALENTS',
    WORK_EXPERIENCE = 'WORK_EXPERIENCE',
    EDUCATION = 'EDUCATION',
    JOB_TITLE_AND_CATEGORY = 'JOB_TITLE_AND_CATEGORY',
    YOUR_TOP_SKILLS = 'YOUR_TOP_SKILLS',
    YOUR_FUNCTIONAL_AND_GLOBAL_SKILLS = 'YOUR_FUNCTIONAL_AND_GLOBAL_SKILLS',
    YOUR_INDUSTRY_EXPERTISE = 'YOUR_INDUSTRY_EXPERTISE',
    WORKING_LANGUAGES = 'WORKING_LANGUAGES',
    LEVEL_OF_EXPERIENCE = 'LEVEL_OF_EXPERIENCE',

    DAILY_RATE = 'DAILY_RATE',

    LOCATION_AND_WORKPLACE_PREFERENCE = 'LOCATION_AND_WORKPLACE_PREFERENCE',

    YOUR_PROFILE_PICTURE = 'YOUR_PROFILE_PICTURE',
    YOUR_PHONE_NUMBER = 'YOUR_PHONE_NUMBER',
    YOUR_PRIVACY_SETTINGS = 'YOUR_PRIVACY_SETTINGS',

    // Strategy
    SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',

    // Not accessible from workflow, only from rerouting
    REMINDER = 'REMINDER',
    WELCOME_BACK = 'WELCOME_BACK',
}

// Ensure all paths declared here exist in pages/freelancer-signup/index/
export enum FreelancerSignupStepRoute {
    WELCOME_TO_MALT_REGULAR = '/freelancer-signup/welcome-to-malt',
    WELCOME_TO_MALT_OPEN = '/freelancer-signup/welcome-to-malt-open',
    WELCOME_TO_MALT_STRATEGY = '/freelancer-signup/welcome-to-malt-strategy',

    FREELANCING_EXPERIENCE = '/freelancer-signup/your-freelancing-experience',
    DESCRIBE_YOURSELF = '/freelancer-signup/how-would-you-describe-yourself',
    CREATE_YOUR_ACCOUNT = '/freelancer-signup/create-your-account',
    VERIFY_YOUR_EMAIL = '/freelancer-signup/verify-your-email',
    COMPLETE_YOUR_ACCOUNT = '/freelancer-signup/complete-your-account',

    SHOWCASE_YOUR_TALENTS = '/freelancer-signup/showcase-your-talents',
    WORK_EXPERIENCE = '/freelancer-signup/your-work-experience',
    EDUCATION = '/freelancer-signup/your-education',
    JOB_TITLE_AND_CATEGORY = '/freelancer-signup/your-job-title-and-category',
    YOUR_TOP_SKILLS = '/freelancer-signup/your-top-skills',
    YOUR_FUNCTIONAL_AND_GLOBAL_SKILLS = '/freelancer-signup/your-skills',
    YOUR_INDUSTRY_EXPERTISE = '/freelancer-signup/your-industry-expertise',
    WORKING_LANGUAGES = '/freelancer-signup/your-working-languages',
    LEVEL_OF_EXPERIENCE = '/freelancer-signup/your-level-of-experience',

    DAILY_RATE = '/freelancer-signup/your-daily-rate',
    LOCATION_AND_WORKPLACE_PREFERENCE = '/freelancer-signup/your-location-and-workplace-preference',
    YOUR_PROFILE_PICTURE = '/freelancer-signup/your-profile-picture',
    YOUR_PHONE_NUMBER = '/freelancer-signup/your-phone-number',
    YOUR_PRIVACY_SETTINGS = '/freelancer-signup/your-privacy-settings',
    SUBMIT_APPLICATION = '/freelancer-signup/submit-your-application',

    // Not accessible from workflow, only from rerouting
    WELCOME_BACK = '/freelancer-signup/welcome-back',
    REDIRECT_TO_REGULAR_WORKFLOW = '/freelancer-signup/redirect-to-regular',
}

export enum FreelancerSignupStepGroup {
    INTRODUCE_YOURSELF = 'INTRODUCE_YOURSELF',
    SHOWCASE_YOUR_TALENT = 'SHOWCASE_YOUR_TALENT',
    SET_PREFERENCES = 'SET_PREFERENCES',
    READY_TO_GO = 'READY_TO_GO',
}

export type FreelancerSignupStepConfiguration = {
    name: FreelancerSignupStepName;
    routeTo: FreelancerSignupStepRoute;
    group: FreelancerSignupStepGroup;
    subStep: number;
    previousEnabled: boolean;
    isAccessible: boolean;
    isCompleted: boolean;
    hideShapes: boolean;
};

export type FreelancerSignupGroupStep = {
    group: FreelancerSignupStepGroup;
    groupPosition: number;
    nbSubStep: number;
};
