import {computed, inject, ref} from '#imports';
import {defineStore} from 'pinia';
import type {ExpertiseItem} from '~/components/_common/expertise/ExpertiseItem';
import {combineGlobalAndFunctionalSkills, filterAdditionalSkills, filterTopSkills} from '~/components/_common/expertise/ExpertiseItem';
import {RegisteredRepositories} from '~/config/application.services';
import {initFetchErrors, type ProfileFetchErrors} from '~/store/profileFetchErrors';
import type {PriceStat} from '~/types/PriceStat';
import type {AdminEventRequest, MinMaxRuleResource, ProfileCompletionCheckListResource, UpdateSeoUrlRequest} from '~/types/api-raw-types';
import type {AdminEvents} from '~/types/domain/AdminEvents';
import type {Appraisal, Appraisals} from '~/types/domain/Appraisals';
import type {Breadcrumb} from '~/types/domain/Breadcrumb';
import type {CategorizationCategory, CategorizationFamily} from '~/types/domain/Categorization';
import type {Certifications} from '~/types/domain/Certifications';
import type {CompanySuggestions} from '~/types/domain/CompanySuggestions';
import type {DevDetails} from '~/types/domain/DevDetails';
import type {Educations} from '~/types/domain/Educations';
import type {Experiences} from '~/types/domain/Experiences';
import type {FreelanceCharter} from '~/types/domain/FreelanceCharter';
import type {LanguageLevel} from '~/types/domain/Languages';
import {Languages} from '~/types/domain/Languages';
import type {LocationPreferences} from '~/types/domain/LocationPreferences';
import type {MissionPreferences} from '~/types/domain/MissionPreferences';
import type {Portfolio} from '~/types/domain/Portfolio';
import type {ExperienceLevel, Profile} from '~/types/domain/Profile';
import type {ProfileAdmin} from '~/types/domain/ProfileAdmin';
import {ProfileExpertises} from '~/types/domain/ProfileExpertises';
import type {ProfileStoreRequest} from '~/types/domain/ProfileStoreRequest';
import type {ProfileWebPreferences} from '~/types/domain/ProfileWebPreferences';
import type {RecommendationRequest} from '~/types/domain/RecommendationRequest';
import type {Recommendation, Recommendations} from '~/types/domain/Recommendations';
import type {SectionsVisibilityResource} from '~/types/domain/SectionsVisibility';
import type {SimilarProfile} from '~/types/domain/SimilarProfile';
import {MinMaxRule} from '~/types/domain/ValidationRule';
import type {VerificationChecklist} from '~/types/domain/VerificationChecklist';
import type {Visibility} from '~/types/domain/Visibility';
import type {FunctionalScopesAvailable} from '~/types/domain/skill/Functional';
import type {IndustryExpertisesAvailable, UpdateIndustryExpertiseRequest} from '~/types/domain/skill/Industry';
import type {UpdateCertificationRequest} from '~/types/infra/CertificationRequest';
import type {UpdateEducationRequest} from '~/types/infra/EducationRequest';
import type {ExperienceRequest, UpdateExperienceRequest} from '~/types/infra/ExperienceRequest';
import type {Collaborators, ProfileRelationsResource} from '~/types/infra/repository/ProfileRepositoryAPI';
import {collaboratorsMapper} from './profileRelations/profileRelationsMapper';
import type {PostalAddress} from '@api';
import {Configuration, ExpertiseApi} from '@api';
import {expertiseApiMapper} from '~/features/expertises/helpers/ExpertiseApiMapper';

export type UpdatePriceRequest = {
    price: number;
    currencyCode: string;
    priceHidden: boolean;
};

export interface UpdateBreadcrumbRequest {
    family: string;
    category?: string;
    job?: string;
}

export interface LanguageRequest {
    code: string;
    name: string;
}

export interface LanguageAndLevelRequest {
    language: LanguageRequest;
    level: LanguageLevel;
}

export interface UpdateLanguageRequest {
    languages: LanguageAndLevelRequest[];
}

export type InvitationsRequest = {
    emails: string;
    message: string;
    experienceId: string | null;
};

export interface Skill {
    label: string;
    occurences?: number;
}
export interface UpdateActivityLocationsRequest {
    address: PostalAddress;
    addressRadius: number;
    remoteOnly: boolean;
    activityLocations: {address: PostalAddress | null; radius: number; serverError?: string}[];
    willingnessToTravelInternationally?: boolean;
}

export interface LocationPreferenceValidationError {
    type: 'INVALID_ADDRESS' | 'ACTIVITY_LOCATION_IS_TOO_FAR' | 'INVALID_ACTIVITY_LOCATION_ADDRESS';
    index: number | null;
}

export interface ActivityLocationWithLabel {
    address: PostalAddress;
    radius?: number;
    label: string;
}

export interface HotReloadedProfileLocation {
    fromSearch: boolean;
    remoteOnly: boolean;
}

export interface UpdateActivityLocationsResource {
    address: PostalAddress;
    addressRadius: number;
    remoteOnly: boolean;
    unlimitedActivityLocation: boolean;
    activityLocations: ActivityLocationWithLabel[];
    validationErrors: LocationPreferenceValidationError[];
    hotReloadedProfileLocation: HotReloadedProfileLocation;
}

export const useProfileStore = defineStore('profile', () => {
    const portfolioRepository = inject(RegisteredRepositories.injectionKeys.portfolio)!;
    const profileRepository = inject(RegisteredRepositories.injectionKeys.profile)!;
    const recommendationRepository = inject(RegisteredRepositories.injectionKeys.recommendation)!;
    const adminEventRepository = inject(RegisteredRepositories.injectionKeys.adminEvents)!;
    const webPreferencesRepository = inject(RegisteredRepositories.injectionKeys.webPreferences)!;

    const apiConfiguration = new Configuration({basePath: ''});
    const expertiseApi = new ExpertiseApi(apiConfiguration);

    const profile = ref<Profile>();
    const breadcrumb = ref<Breadcrumb>();
    const adminEvents = ref<AdminEvents>();
    const devDetails = ref<DevDetails>();
    const freelanceCharter = ref<FreelanceCharter>();
    const experiences = ref<Experiences>();
    const educations = ref<Educations>();
    const certifications = ref<Certifications>();
    const profileExpertises = ref<ProfileExpertises>();
    const globalAndFunctionalSkills = ref<ExpertiseItem[]>([]);
    const topSkills = ref<ExpertiseItem[]>([]);
    const additionalSkills = ref<ExpertiseItem[]>([]);
    const visibility = ref<Visibility>();
    const profileAdmin = ref<ProfileAdmin>();
    const appraisals = ref<Appraisals>();
    const missionPreferences = ref<MissionPreferences>();
    const portfolio = ref<Portfolio>();
    const similars = ref<SimilarProfile[]>();
    const sectionsVisibility = ref<SectionsVisibilityResource>();
    const recommendations = ref<Recommendations>();
    const verificationChecklist = ref<VerificationChecklist>();
    const completionList = ref<{items: ProfileCompletionCheckListResource}>();
    const industryExpertiseRule = ref<MinMaxRuleResource>();
    const webPreferences = ref<ProfileWebPreferences>();
    const relations = ref<ProfileRelationsResource>();
    const collaboratorsInfo = ref<Collaborators>();
    const averagePriceCurrentFreelancer = ref<PriceStat>();
    const profileFetchErrors = ref<ProfileFetchErrors>(initFetchErrors());

    const hasNoMissionPreference = computed(
        () =>
            missionPreferences.value?.activityArea.length === 0 &&
            missionPreferences.value.companySize.length === 0 &&
            missionPreferences.value.projectDuration.length === 0,
    );
    const profileLocationPreferences = computed((): LocationPreferences | undefined => profile.value?.locationPreferences);

    async function getProfile(request: ProfileStoreRequest): Promise<Profile> {
        profile.value = await profileRepository.fetchProfile(
            request.details.route.profileIdOrAlias,
            request.details.registry,
            request.details.route.queryParameters || '',
        );

        return profile.value;
    }

    async function getRelations(): Promise<{
        relations: ProfileRelationsResource | undefined;
        collaboratorsInfo: Collaborators | undefined;
    }> {
        relations.value = await profileRepository.fetchRelations(profile.value!);
        collaboratorsInfo.value = collaboratorsMapper(relations?.value);

        return {relations: relations.value, collaboratorsInfo: collaboratorsInfo.value};
    }

    async function deleteRecommendation(recommendation: Recommendation) {
        await recommendationRepository.deleteRecommendation(recommendation);
        await getRecommendations();
    }

    function addAdminEvent(adminEvent: AdminEventRequest, profile: Profile) {
        return adminEventRepository.addAdminEvent(adminEvent, profile);
    }

    function acknowledgeOnboarding(profile: Profile): Promise<void> {
        return webPreferencesRepository.acknowledgeOnboarding(profile);
    }

    async function acknowledgeHeadlineTour(): Promise<void> {
        await webPreferencesRepository.acknowledgeHeadlineTour(profile.value!);
    }

    async function adminAcknowledgeOnboarding(acknowledge: boolean): Promise<void> {
        await webPreferencesRepository.adminAcknowledgeOnboarding(profile.value!, acknowledge);
    }

    async function getWebPreferences(): Promise<ProfileWebPreferences | undefined> {
        webPreferences.value = await webPreferencesRepository.getPreferences(profile.value!);

        return webPreferences.value;
    }

    async function getCompletionList() {
        completionList.value = await profileRepository.fetchCompletionList(profile.value!);
    }

    async function getAppraisals(): Promise<Appraisals | undefined> {
        appraisals.value = await profileRepository.fetchAppraisals(profile.value!);

        return appraisals.value;
    }

    async function getVisibility() {
        visibility.value = await profileRepository.fetchVisibility(profile.value!);
    }

    async function getSimilars() {
        similars.value = await profileRepository.fetchSimilarProfiles(profile.value!);

        return similars;
    }

    async function getMissionPreferences(): Promise<MissionPreferences | undefined> {
        missionPreferences.value = await profileRepository.fetchMissionPreferences(profile.value!);

        return missionPreferences.value;
    }

    async function getFreelanceCharter() {
        freelanceCharter.value = await profileRepository.fetchFreelanceCharter(profile.value!);
    }

    async function getPortfolio(): Promise<Portfolio | undefined> {
        portfolio.value = await portfolioRepository.fetchPortfolio(profile.value!);

        return portfolio.value;
    }

    async function getRecommendations(): Promise<Recommendations | undefined> {
        try {
            recommendations.value = await profileRepository.fetchRecommendations(profile.value!);
        } catch (error: any) {
            console.error(error);
            throw error;
        }
        return recommendations.value;
    }

    async function getBreadcrumb(): Promise<Breadcrumb | undefined> {
        breadcrumb.value = await profileRepository.fetchBreadcrumb(profile.value!);

        return breadcrumb.value;
    }

    async function getDevDetails() {
        devDetails.value = await profileRepository.fetchDevDetails(profile.value!);
    }

    async function getAdminEvents() {
        adminEvents.value = await profileRepository.fetchAdminEvents(profile.value!);
    }

    async function getAveragePriceCurrentFreelancer() {
        averagePriceCurrentFreelancer.value = await profileRepository.fetchAveragePriceCurrentFreelancer(profile.value!);
    }

    async function getProfileAdmin() {
        profileAdmin.value = await profileRepository.fetchProfileAdmin(profile.value!);
    }

    async function reloadEducations(): Promise<Educations | undefined> {
        educations.value = await profileRepository.fetchEducations(profile.value!);

        return educations.value;
    }

    async function reloadCertifications(): Promise<Certifications | undefined> {
        certifications.value = await profileRepository.fetchCertifications(profile.value!);

        return certifications.value;
    }

    async function reloadExperiences(): Promise<Experiences | undefined> {
        experiences.value = await profileRepository.fetchExperiences(profile.value!);

        return experiences.value;
    }

    async function reloadProfileExpertises(): Promise<ProfileExpertises | undefined> {
        try {
            profileExpertises.value = await profileRepository.fetchProfileExpertises(profile.value!);
            globalAndFunctionalSkills.value = combineGlobalAndFunctionalSkills(
                profileExpertises.value.globalSkills,
                profileExpertises.value.functionalSkills,
            );
            topSkills.value = filterTopSkills(globalAndFunctionalSkills.value);
            additionalSkills.value = filterAdditionalSkills(globalAndFunctionalSkills.value);
            profileFetchErrors.value.expertises = false;
        } catch (e) {
            profileExpertises.value = new ProfileExpertises({
                functionalSkills: [],
                globalSkills: [],
                industryExpertises: [],
                selectedSkillsOrder: [],
                topSkills: [],
            });
            profileFetchErrors.value.expertises = true;
        }

        return profileExpertises.value;
    }

    async function getSectionsVisibility(params: string | null = null) {
        sectionsVisibility.value = await profileRepository.fetchSectionsVisibility(profile.value!, params);

        return sectionsVisibility.value;
    }

    async function getVerificationChecklist(): Promise<VerificationChecklist | undefined> {
        verificationChecklist.value = await profileRepository.fetchVerificationChecklist(profile.value!);

        return verificationChecklist.value;
    }

    async function updateHeadline(headline: string) {
        await profileRepository.updateHeadline(profile.value!, headline);
        profile.value!.headline = headline;
    }

    async function updateDisplayOption(option: string) {
        await profileRepository.updateDisplayOption(profile.value!, option);
        profile.value!.privacy.nameDisplayOption = option;
    }

    async function updateExperienceLevel(experienceLevel: ExperienceLevel) {
        await profileRepository.updateExperienceLevel(profile.value!, experienceLevel);
        profile.value!.experienceLevel = experienceLevel;
    }

    async function updatePrice(request: UpdatePriceRequest) {
        await profileRepository.updatePrice(profile.value!, request);
        profile.value!.price.price = request.price;
        profile.value!.price.currencyCode = request.currencyCode;
        profile.value!.price.priceHidden = request.priceHidden;
    }

    async function updateDescription(description: string) {
        await profileRepository.updateDescription(profile.value!, description);
        profile.value!.description = description;
        await getCompletionList();
    }

    async function updateExperiences(request: UpdateExperienceRequest) {
        await profileRepository.updateExperiences(profile.value!, request);
        await reloadExperiences();
        await getCompletionList();
    }

    async function updateExperience(request: ExperienceRequest) {
        await profileRepository.updateExperience(profile.value!, request);
        await reloadExperiences();
        await getCompletionList();
    }

    async function reorderExperiences(request: string[]) {
        await profileRepository.reorderExperiences(profile.value!, request);
        await reloadExperiences();
    }

    async function addExperience(request: ExperienceRequest) {
        await profileRepository.addExperience(profile.value!, request);
        await reloadExperiences();
        await getCompletionList();
    }

    async function deleteExperience(experienceId: string) {
        await profileRepository.deleteExperience(profile.value!, experienceId);
        await reloadExperiences();
        await getCompletionList();
    }

    async function updateEducations(request: UpdateEducationRequest) {
        await profileRepository.updateEducations(profile.value!, request);
        await reloadEducations();
    }

    async function updateCertifications(request: UpdateCertificationRequest) {
        await profileRepository.updateCertifications(profile.value!, request);
        await reloadCertifications();
    }

    async function uploadPhoto(file: File): Promise<any> {
        await profileRepository.uploadPhoto(profile.value!, file);
        await reloadPhoto();
    }

    async function reloadPhoto() {
        profile.value!.photo = await profileRepository.reloadPhoto(profile.value!);
    }

    async function updateBreadcrumb(breadcrumbRequest: UpdateBreadcrumbRequest) {
        await profileRepository.updateBreadcrumb(profile.value!, breadcrumbRequest);
        await getBreadcrumb();
    }

    async function resendAccountValidationEmail() {
        await profileRepository.resendEmailValidation(profile.value!);
    }

    async function updateLanguages(request: UpdateLanguageRequest) {
        await profileRepository.updateLanguages(profile.value!, request);
        profile.value!.languages = Languages.fromRequest(request);
    }

    async function autocompleteSkills(query: string): Promise<Skill[]> {
        return await profileRepository.autocompleteSkills(profile.value!, query);
    }

    async function autocompleteCompanies(query: string): Promise<CompanySuggestions> {
        return await profileRepository.autocompleteCompanies(profile.value!, query);
    }

    async function listAllFunctionalScopes(): Promise<FunctionalScopesAvailable> {
        return await profileRepository.fetchFunctionalScopes(profile.value!);
    }

    async function listAllIndustryExpertise(): Promise<IndustryExpertisesAvailable> {
        return await profileRepository.fetchIndustryExpertises(profile.value!);
    }

    async function getIndustryExpertiseRules(): Promise<MinMaxRule> {
        if (!industryExpertiseRule.value) {
            industryExpertiseRule.value = await profileRepository.fetchIndustryExpertisesRule(profile.value!);
        }
        return new MinMaxRule(industryExpertiseRule.value);
    }

    async function updateIndustryExpertise(request: UpdateIndustryExpertiseRequest) {
        await expertiseApi.updateIndustryExpertises(expertiseApiMapper.toUpdateIndustryExpertisesRequestApi(request));
        await reloadProfileExpertises();
    }

    const displayName = computed(() => {
        return displayNameWithOption(profile.value?.privacy.nameDisplayOption!);
    });

    function displayNameWithOption(option: string) {
        if (option === 'INITIAL') {
            return `${profile.value?.firstname} ${profile.value?.lastname.charAt(0).toUpperCase()}.`;
        }

        if (option === 'INITIALS') {
            return `${profile.value?.firstname.charAt(0).toUpperCase()}. ${profile.value?.lastname.charAt(0).toUpperCase()}.`;
        }

        return `${profile.value?.firstname} ${profile.value?.lastname}`;
    }

    async function updateAppraisalVisibility(appraisal: Appraisal) {
        await profileRepository.updateAppraisalVisibility(appraisal);
        appraisal.hidden = !appraisal.hidden;
    }

    function updateActivityLocations(payload: UpdateActivityLocationsRequest) {
        return profileRepository.updateActivityLocations(profile.value!, payload);
    }

    async function sendRecommendation(recommendationRequest: RecommendationRequest): Promise<void> {
        await profileRepository.sendRecommendation(recommendations.value!, recommendationRequest);
        await getRecommendations();
    }

    function sendRecommendationRequest(payload: InvitationsRequest): Promise<any> {
        return profileRepository.sendRecommendationRequest(profile.value!, payload);
    }

    function updateSeoUrl(payload: UpdateSeoUrlRequest): Promise<any> {
        return profileRepository.updateSeoUrl(profile.value!, payload);
    }

    const profilePreferredFamily = computed((): CategorizationFamily | undefined => {
        const preferredFamily = profile.value?.categorization.families.filter(
            (family: CategorizationFamily) => family.id === profile.value?.categorization.preferredFamily,
        );

        return preferredFamily && preferredFamily.length > 0 ? preferredFamily[0] : undefined;
    });
    const profilePreferredCategory = computed((): CategorizationCategory | undefined => {
        const preferredCategory = profilePreferredFamily.value?.categories?.filter(
            (category: CategorizationCategory) => category.id === profile.value?.categorization.preferredCategory,
        );

        return preferredCategory && preferredCategory.length > 0 ? preferredCategory[0] : undefined;
    });

    return {
        averagePriceCurrentFreelancer,
        profileLocationPreferences,
        hasNoMissionPreference,
        appraisals,
        completionList,
        breadcrumb,
        devDetails,
        freelanceCharter,
        experiences,
        educations,
        certifications,
        profileExpertises,
        globalAndFunctionalSkills,
        topSkills,
        additionalSkills,
        adminEvents,
        profileAdmin,
        missionPreferences,
        portfolio,
        profile,
        relations,
        collaboratorsInfo,
        similars,
        visibility,
        sectionsVisibility,
        recommendations,
        verificationChecklist,
        webPreferences,
        deleteRecommendation,
        addAdminEvent,
        acknowledgeHeadlineTour,
        acknowledgeOnboarding,
        adminAcknowledgeOnboarding,
        getWebPreferences,
        getProfile,
        getVisibility,
        getSimilars,
        getAppraisals,
        getRelations,
        getFreelanceCharter,
        getMissionPreferences,
        getPortfolio,
        getRecommendations,
        getDevDetails,
        getAdminEvents,
        getProfileAdmin,
        getBreadcrumb,
        getVerificationChecklist,
        getCompletionList,
        getSectionsVisibility,
        getAveragePriceCurrentFreelancer,
        profilePreferredCategory,
        profilePreferredFamily,
        reloadExperiences,
        reloadEducations,
        reloadCertifications,
        reloadProfileExpertises,
        profileFetchErrors,
        displayName,
        displayNameWithOption,
        updateHeadline,
        updateDisplayOption,
        updateExperienceLevel,
        updatePrice,
        updateDescription,
        updateEducations,
        updateCertifications,
        uploadPhoto,
        reloadPhoto,
        updateBreadcrumb,
        resendAccountValidationEmail,
        sendRecommendation,
        sendRecommendationRequest,
        updateLanguages,
        autocompleteSkills,
        autocompleteCompanies,
        updateAppraisalVisibility,
        updateActivityLocations,
        updateExperiences,
        updateExperience,
        reorderExperiences,
        addExperience,
        deleteExperience,
        updateSeoUrl,
        listAllFunctionalScopes,
        listAllIndustryExpertise,
        updateIndustryExpertise,
        getIndustryExpertiseRules,
    };
});
