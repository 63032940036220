import type {PriceStatResource} from '~/types/api-raw-types';

export class PriceStat {
    minPrice: number;
    maxPrice: number;
    feeRateStandard: number;
    feeRateReduced: number;

    constructor(params: PriceStatResource) {
        this.minPrice = params.minPrice;
        this.maxPrice = params.maxPrice;
        this.feeRateStandard = params.feeRateStandard;
        this.feeRateReduced = params.feeRateReduced;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
