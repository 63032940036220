/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { UpdateProfileGlobalSkillRequest } from './';
import {
    UpdateProfileGlobalSkillRequestFromJSON,
    UpdateProfileGlobalSkillRequestFromJSONTyped,
    UpdateProfileGlobalSkillRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateProfileGlobalSkillsRequest
 */
export interface UpdateProfileGlobalSkillsRequest {
    /**
     * 
     * @type {Array<UpdateProfileGlobalSkillRequest>}
     * @memberof UpdateProfileGlobalSkillsRequest
     */
    globalSkills: Array<UpdateProfileGlobalSkillRequest>;
}

/**
 * Check if a given object implements the UpdateProfileGlobalSkillsRequest interface.
 */
export function instanceOfUpdateProfileGlobalSkillsRequest(value: object): value is UpdateProfileGlobalSkillsRequest {
    if (!('globalSkills' in value) || value['globalSkills'] === undefined) return false;
    return true;
}

export function UpdateProfileGlobalSkillsRequestFromJSON(json: any): UpdateProfileGlobalSkillsRequest {
    return UpdateProfileGlobalSkillsRequestFromJSONTyped(json, false);
}

export function UpdateProfileGlobalSkillsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileGlobalSkillsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'globalSkills': ((json['globalSkills'] as Array<any>).map(UpdateProfileGlobalSkillRequestFromJSON)),
    };
}

export function UpdateProfileGlobalSkillsRequestToJSON(value?: UpdateProfileGlobalSkillsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'globalSkills': ((value['globalSkills'] as Array<any>).map((v) => UpdateProfileGlobalSkillRequestToJSON(v))),
    };
}

