/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProfileFunctionalSkillRequest
 */
export interface UpdateProfileFunctionalSkillRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileFunctionalSkillRequest
     */
    id: string;
}

/**
 * Check if a given object implements the UpdateProfileFunctionalSkillRequest interface.
 */
export function instanceOfUpdateProfileFunctionalSkillRequest(value: object): value is UpdateProfileFunctionalSkillRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function UpdateProfileFunctionalSkillRequestFromJSON(json: any): UpdateProfileFunctionalSkillRequest {
    return UpdateProfileFunctionalSkillRequestFromJSONTyped(json, false);
}

export function UpdateProfileFunctionalSkillRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileFunctionalSkillRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function UpdateProfileFunctionalSkillRequestToJSON(value?: UpdateProfileFunctionalSkillRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
    };
}

