import {useUniversalFetch, useUniversalPost} from '#imports';
import {ProfileWebPreferences} from '~/types/domain/ProfileWebPreferences';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {ProfileWebPreferencesResource} from '~/types/api-raw-types';
import type {Profile} from '~/types/domain/Profile';
import type {WebPreferencesRepository} from '../../domain/repository/WebPreferencesRepository';

export const webPreferencesRepository: WebPreferencesRepository = {
    async adminAcknowledgeOnboarding(profile: Profile, acknowledge: boolean): Promise<void> {
        const url = extractLink(profile.links, 'adminAcknowledgeOnboarding');
        return await useUniversalPost<void>(url, {body: {value: acknowledge}});
    },

    async acknowledgeOnboarding(profile: Profile): Promise<void> {
        const url = extractLink(profile.links, 'acknowledgeProfileOnboarding');
        await useUniversalPost<void>(url);
    },

    async acknowledgeHeadlineTour(profile: Profile): Promise<void> {
        const url = extractLink(profile.links, 'acknowledgeHeadlineTour');
        await useUniversalPost<void>(url);
    },

    async getPreferences(profile: Profile): Promise<ProfileWebPreferences> {
        const url = extractLink(profile.links, 'getWebPreferences');
        const resource = await useUniversalFetch<ProfileWebPreferencesResource>(url);
        return new ProfileWebPreferences(resource);
    },
};
