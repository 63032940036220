import type {SectionVisibilityResource} from '~/types/api-raw-types';

export enum SectionsVisibilityType {
    VISIBILITY_ALERT_BLOCK = 'VISIBILITY_ALERT_BLOCK',
    PORTFOLIO = 'PORTFOLIO',
    EXTERNAL_REPUTATION = 'EXTERNAL_REPUTATION',
    SHARE_YOUR_PROFILE = 'SHARE_YOUR_PROFILE',
    SIMILAR_PROFILES = 'SIMILAR_PROFILES',
    PROPOSE_A_PROJECT = 'PROPOSE_A_PROJECT',
    ADD_TO_FAVORITES = 'ADD_TO_FAVORITES',
    FUNCTIONAL_SKILLS = 'FUNCTIONAL_SKILLS',
    INDUSTRY_EXPERTISE = 'INDUSTRY_EXPERTISE',
    WORKPLACE_PREFERENCES = 'WORKPLACE_PREFERENCES',
    ONBOARDING_STRATEGY = 'ONBOARDING_STRATEGY',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    BREADCRUMBS = 'BREADCRUMBS',
    BADGES = 'BADGES',
    TRANSLATION = 'TRANSLATION',
    SEARCH_LINKS = 'SEARCH_LINKS',
    VIEW_MORE_LOCK = 'VIEW_MORE_LOCK',
}

export type SectionsVisibilityResource = {
    visibleSections: SectionsVisibilityType[];
};

export class SectionsVisibility {
    visibleSections: SectionsVisibilityType[];

    constructor(params: SectionVisibilityResource) {
        this.visibleSections = params?.visible?.map((visibleSection) => SectionsVisibilityType[visibleSection]);
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
