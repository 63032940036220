import {useUniversalDelete} from '#imports';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {Recommendation} from '~/types/domain/Recommendations';
import type {RecommendationRepository} from '../../domain/repository/RecommendationRepository';

function deleteRecommendation(recommendation: Recommendation): Promise<void> {
    const url = extractLink(recommendation.links, 'deleteRecommendation');
    return useUniversalDelete(url);
}

export const recommendationRepository: RecommendationRepository = {
    deleteRecommendation,
};
