import type {ProfileCompletionResource, ProfileCompletionTaskResource} from '@/types/api-raw-types';
import {AbstractResource} from 'vue3-starter/src/typescript/resource/AbstractResource';

export enum TaskCardType {
    CHARTER = 'CHARTER',
    DESCRIPTION = 'DESCRIPTION',
    EXPERIENCE = 'EXPERIENCE',
    FORMATION_OR_CERTIFICATION = 'FORMATION_OR_CERTIFICATION',
    LANGUAGE = 'LANGUAGE',
    MALT_OPEN = 'MALT_OPEN',
    MISSION_PREFERENCES = 'MISSION_PREFERENCES',
    NETWORKS = 'NETWORKS',
    PHOTO = 'PHOTO',
    PORTFOLIO = 'PORTFOLIO',
    RECOMMENDATIONS = 'RECOMMENDATIONS',
    TAGS = 'TAGS',
    VERIFIED_EMAIL_ADDRESS = 'VERIFIED_EMAIL_ADDRESS',
    WORKPLACE_PREFERENCE = 'WORKPLACE_PREFERENCE',
}

export class TaskCard extends AbstractResource {
    dismissible: boolean;
    iconName: string;
    type: TaskCardType;

    constructor(params: ProfileCompletionTaskResource) {
        super(params);
        this.dismissible = this.links.dismiss != null;
        this.iconName = params.icon;
        this.type = TaskCardType[params.type as TaskCardType];
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class TaskCards extends AbstractResource {
    tasks: TaskCard[];

    constructor(params: ProfileCompletionResource) {
        super(params);
        this.tasks = params.tasks.map((taskParams: ProfileCompletionTaskResource) => {
            return new TaskCard(taskParams);
        });
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
