
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as edit_45availabilitysa0vDJFBhqMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/[profileIdOrAlias]/edit-availability.vue?macro=true";
import { default as _91profileIdOrAlias_93T8cUuzc9PUMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/[profileIdOrAlias].vue?macro=true";
import { default as _91step_93gMqbrFut67Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/completion/[step].vue?macro=true";
import { default as indexuC6RPU0pALMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/cv/[profileIdOrAlias]/index.vue?macro=true";
import { default as pdfJTUEw2bzNmMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/cv/[profileIdOrAlias]/pdf.vue?macro=true";
import { default as indexdO3ssiyu6VMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-charter/index.vue?macro=true";
import { default as demoVmiDDFeeJpMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/demo.vue?macro=true";
import { default as complete_45your_45accountTeVSdx4lH1Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/complete-your-account.vue?macro=true";
import { default as create_45your_45accountZ1FBRUYzZGMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/create-your-account.vue?macro=true";
import { default as how_45would_45you_45describe_45yourselfdZ0xQ0lgz3Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/how-would-you-describe-yourself.vue?macro=true";
import { default as redirect_45to_45regularMDki1dAOmcMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/redirect-to-regular.vue?macro=true";
import { default as showcase_45your_45talents2913QsxUfnMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/showcase-your-talents.vue?macro=true";
import { default as submit_45your_45applicationFiMIZQLmhPMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/submit-your-application.vue?macro=true";
import { default as verify_45your_45emailWFcnITYZeQMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/verify-your-email.vue?macro=true";
import { default as welcome_45backDo2pJOYYobMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-back.vue?macro=true";
import { default as welcome_45to_45malt_45open01MytqVjaPMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt-open.vue?macro=true";
import { default as welcome_45to_45malt_45strategyhnseqIvWqYMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt-strategy.vue?macro=true";
import { default as welcome_45to_45malt_91_91funnelContext_93_938fqI8IMdYgMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt[[funnelContext]].vue?macro=true";
import { default as your_45daily_45ratef0nZxdEChsMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-daily-rate.vue?macro=true";
import { default as your_45educationQTK7EIrAqwMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-education.vue?macro=true";
import { default as your_45freelancing_45experienceNcOUd1IR7xMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-freelancing-experience.vue?macro=true";
import { default as your_45industry_45expertiseW6rfZkdUksMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-industry-expertise.vue?macro=true";
import { default as your_45job_45title_45and_45categoryg9iLl6nQWHMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-job-title-and-category.vue?macro=true";
import { default as your_45level_45of_45experienceFzm7JkBDRHMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-level-of-experience.vue?macro=true";
import { default as your_45location_45and_45workplace_45preferencevscxafGVvPMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-location-and-workplace-preference.vue?macro=true";
import { default as your_45phone_45numberZabnvAbmU9Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-phone-number.vue?macro=true";
import { default as your_45privacy_45settingspXXqF9NmOIMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-privacy-settings.vue?macro=true";
import { default as your_45profile_45pictureMG1aJBTdBpMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-profile-picture.vue?macro=true";
import { default as your_45skillsuWUOvdBgJkMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-skills.vue?macro=true";
import { default as your_45top_45skillspVN4R4DIuJMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-top-skills.vue?macro=true";
import { default as your_45work_45experienceUnRZYNLqvZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-work-experience.vue?macro=true";
import { default as your_45working_45languagesylvgT4RRWvMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-working-languages.vue?macro=true";
import { default as indexu189mhRbpfMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index.vue?macro=true";
import { default as reminder_45_91reminderToken_93Zvdwt06krbMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/reminder-[reminderToken].vue?macro=true";
import { default as strategyxS1kqGX15CMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/waiting-room/strategy.vue?macro=true";
import { default as index4FQa61dqbTMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/index.vue?macro=true";
import { default as starterS0sGrZEnR3Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile-qualification-panel/starter.vue?macro=true";
import { default as _91profileIdOrAlias_939NIPhBot50Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile/[profileIdOrAlias].vue?macro=true";
import { default as sandboxLUlCiMz4wIMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile/sandbox.vue?macro=true";
import { default as indexxgM428TGAxMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mission-preferences/index.vue?macro=true";
import { default as portfolio0McpWlTu2HMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/portfolio.vue?macro=true";
import { default as indexZfrFA6WkdoMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/recommendations/index.vue?macro=true";
import { default as auth_45confirmedVdcEjXCGmpMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/reputation-provider/auth-confirmed.vue?macro=true";
import { default as _91step_93CIMjaCwTXEMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/signup/[step].vue?macro=true";
export default [
  {
    name: "profileIdOrAlias",
    path: "/:profileIdOrAlias()",
    meta: _91profileIdOrAlias_93T8cUuzc9PUMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/[profileIdOrAlias].vue"),
    children: [
  {
    name: "profileIdOrAlias-edit-availability",
    path: "edit-availability",
    meta: edit_45availabilitysa0vDJFBhqMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/[profileIdOrAlias]/edit-availability.vue")
  }
]
  },
  {
    name: "completion-step",
    path: "/completion/:step()",
    meta: _91step_93gMqbrFut67Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/completion/[step].vue")
  },
  {
    name: "cv-profileIdOrAlias",
    path: "/cv/:profileIdOrAlias()",
    meta: indexuC6RPU0pALMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/cv/[profileIdOrAlias]/index.vue")
  },
  {
    name: "cv-profileIdOrAlias-pdf",
    path: "/cv/:profileIdOrAlias()/pdf",
    meta: pdfJTUEw2bzNmMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/cv/[profileIdOrAlias]/pdf.vue")
  },
  {
    name: "freelancer-charter",
    path: "/freelancer-charter",
    meta: indexdO3ssiyu6VMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-charter/index.vue")
  },
  {
    name: "freelancer-signup-demo",
    path: "/freelancer-signup/demo",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/demo.vue")
  },
  {
    name: "freelancer-signup",
    path: "/freelancer-signup",
    meta: indexu189mhRbpfMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index.vue"),
    children: [
  {
    name: "freelancer-signup-index-complete-your-account",
    path: "complete-your-account",
    meta: complete_45your_45accountTeVSdx4lH1Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/complete-your-account.vue")
  },
  {
    name: "freelancer-signup-index-create-your-account",
    path: "create-your-account",
    meta: create_45your_45accountZ1FBRUYzZGMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/create-your-account.vue")
  },
  {
    name: "freelancer-signup-index-how-would-you-describe-yourself",
    path: "how-would-you-describe-yourself",
    meta: how_45would_45you_45describe_45yourselfdZ0xQ0lgz3Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/how-would-you-describe-yourself.vue")
  },
  {
    name: "freelancer-signup-index-redirect-to-regular",
    path: "redirect-to-regular",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/redirect-to-regular.vue")
  },
  {
    name: "freelancer-signup-index-showcase-your-talents",
    path: "showcase-your-talents",
    meta: showcase_45your_45talents2913QsxUfnMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/showcase-your-talents.vue")
  },
  {
    name: "freelancer-signup-index-submit-your-application",
    path: "submit-your-application",
    meta: submit_45your_45applicationFiMIZQLmhPMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/submit-your-application.vue")
  },
  {
    name: "freelancer-signup-index-verify-your-email",
    path: "verify-your-email",
    meta: verify_45your_45emailWFcnITYZeQMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/verify-your-email.vue")
  },
  {
    name: "freelancer-signup-index-welcome-back",
    path: "welcome-back",
    meta: welcome_45backDo2pJOYYobMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-back.vue")
  },
  {
    name: "freelancer-signup-index-welcome-to-malt-open",
    path: "welcome-to-malt-open",
    meta: welcome_45to_45malt_45open01MytqVjaPMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt-open.vue")
  },
  {
    name: "freelancer-signup-index-welcome-to-malt-strategy",
    path: "welcome-to-malt-strategy",
    meta: welcome_45to_45malt_45strategyhnseqIvWqYMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt-strategy.vue")
  },
  {
    name: "freelancer-signup-index-welcome-to-maltfunnelContext",
    path: "welcome-to-malt:funnelContext?",
    meta: welcome_45to_45malt_91_91funnelContext_93_938fqI8IMdYgMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/welcome-to-malt[[funnelContext]].vue")
  },
  {
    name: "freelancer-signup-index-your-daily-rate",
    path: "your-daily-rate",
    meta: your_45daily_45ratef0nZxdEChsMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-daily-rate.vue")
  },
  {
    name: "freelancer-signup-index-your-education",
    path: "your-education",
    meta: your_45educationQTK7EIrAqwMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-education.vue")
  },
  {
    name: "freelancer-signup-index-your-freelancing-experience",
    path: "your-freelancing-experience",
    meta: your_45freelancing_45experienceNcOUd1IR7xMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-freelancing-experience.vue")
  },
  {
    name: "freelancer-signup-index-your-industry-expertise",
    path: "your-industry-expertise",
    meta: your_45industry_45expertiseW6rfZkdUksMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-industry-expertise.vue")
  },
  {
    name: "freelancer-signup-index-your-job-title-and-category",
    path: "your-job-title-and-category",
    meta: your_45job_45title_45and_45categoryg9iLl6nQWHMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-job-title-and-category.vue")
  },
  {
    name: "freelancer-signup-index-your-level-of-experience",
    path: "your-level-of-experience",
    meta: your_45level_45of_45experienceFzm7JkBDRHMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-level-of-experience.vue")
  },
  {
    name: "freelancer-signup-index-your-location-and-workplace-preference",
    path: "your-location-and-workplace-preference",
    meta: your_45location_45and_45workplace_45preferencevscxafGVvPMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-location-and-workplace-preference.vue")
  },
  {
    name: "freelancer-signup-index-your-phone-number",
    path: "your-phone-number",
    meta: your_45phone_45numberZabnvAbmU9Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-phone-number.vue")
  },
  {
    name: "freelancer-signup-index-your-privacy-settings",
    path: "your-privacy-settings",
    meta: your_45privacy_45settingspXXqF9NmOIMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-privacy-settings.vue")
  },
  {
    name: "freelancer-signup-index-your-profile-picture",
    path: "your-profile-picture",
    meta: your_45profile_45pictureMG1aJBTdBpMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-profile-picture.vue")
  },
  {
    name: "freelancer-signup-index-your-skills",
    path: "your-skills",
    meta: your_45skillsuWUOvdBgJkMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-skills.vue")
  },
  {
    name: "freelancer-signup-index-your-top-skills",
    path: "your-top-skills",
    meta: your_45top_45skillspVN4R4DIuJMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-top-skills.vue")
  },
  {
    name: "freelancer-signup-index-your-work-experience",
    path: "your-work-experience",
    meta: your_45work_45experienceUnRZYNLqvZMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-work-experience.vue")
  },
  {
    name: "freelancer-signup-index-your-working-languages",
    path: "your-working-languages",
    meta: your_45working_45languagesylvgT4RRWvMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/index/your-working-languages.vue")
  }
]
  },
  {
    name: "freelancer-signup-reminder-reminderToken",
    path: "/freelancer-signup/reminder-:reminderToken()",
    meta: reminder_45_91reminderToken_93Zvdwt06krbMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/reminder-[reminderToken].vue")
  },
  {
    name: "freelancer-signup-waiting-room-strategy",
    path: "/freelancer-signup/waiting-room/strategy",
    meta: strategyxS1kqGX15CMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/freelancer-signup/waiting-room/strategy.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index4FQa61dqbTMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/index.vue")
  },
  {
    name: "mf-profile-qualification-panel-starter",
    path: "/mf/profile-qualification-panel/starter",
    meta: starterS0sGrZEnR3Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile-qualification-panel/starter.vue")
  },
  {
    name: "mf-profile-profileIdOrAlias",
    path: "/mf/profile/:profileIdOrAlias()",
    meta: _91profileIdOrAlias_939NIPhBot50Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile/[profileIdOrAlias].vue")
  },
  {
    name: "mf-profile-sandbox",
    path: "/mf/profile/sandbox",
    meta: sandboxLUlCiMz4wIMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mf/profile/sandbox.vue")
  },
  {
    name: "mission-preferences",
    path: "/mission-preferences",
    meta: indexxgM428TGAxMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/mission-preferences/index.vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: portfolio0McpWlTu2HMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/portfolio.vue")
  },
  {
    name: "recommendations",
    path: "/recommendations",
    meta: indexZfrFA6WkdoMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/recommendations/index.vue")
  },
  {
    name: "reputation-provider-auth-confirmed",
    path: "/reputation-provider/auth-confirmed",
    meta: auth_45confirmedVdcEjXCGmpMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/reputation-provider/auth-confirmed.vue")
  },
  {
    name: "signup-step",
    path: "/signup/:step()",
    meta: _91step_93CIMjaCwTXEMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/profile/profile-front/src/pages/signup/[step].vue")
  }
]