import type {ProfileSiblingResource} from '~/types/api-raw-types';

export class Siblings {
    previousUrl?: string;
    nextUrl?: string;
    isFirstPage: boolean;
    isLastPage: boolean;
    hasPreviousProfile: boolean;
    hasNextProfile: boolean;
    loaded: boolean;

    constructor(params: ProfileSiblingResource) {
        this.previousUrl = params.previousUrl;
        this.nextUrl = params.nextUrl;
        this.isFirstPage = params.firstPage;
        this.isLastPage = params.lastPage;
        this.hasPreviousProfile = params.hasPreviousProfile;
        this.hasNextProfile = params.hasNextProfile;
        this.loaded = true;
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static default(): Siblings {
        const siblings = new Siblings({
            firstPage: false,
            lastPage: false,
            hasNextProfile: false,
            hasPreviousProfile: false,
        });

        siblings.loaded = false;

        return siblings;
    }
}
