import type {AppraisalAverageRatingResource, AppraisalResource, AppraisalsResource, DateAsString} from '~/types/api-raw-types';
import {AbstractResource} from '../../../../../vue3-starter/src/typescript/resource/AbstractResource';

export class Appraisal extends AbstractResource {
    clientCompanyName: string;
    clientImageUrl: string;
    clientName: string;
    comment: string;
    creationDate: DateAsString;
    hidden: boolean;
    override links: any;

    constructor(params: AppraisalResource) {
        super(params);
        this.clientCompanyName = params.clientCompanyName;
        this.clientImageUrl = params.clientImageUrl;
        this.clientName = params.clientName;
        this.comment = params.comment;
        this.creationDate = params.creationDate;
        this.hidden = params.hidden;
        this.links = params._links;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class AppraisalAverageRating {
    communication: number;
    delay: number;
    general: number;
    quality: number;

    constructor(params: AppraisalAverageRatingResource) {
        this.communication = params.communication;
        this.delay = params.delay;
        this.general = params.general;
        this.quality = params.quality;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Appraisals extends AbstractResource {
    missionCount: number;
    hiddenAppraisals: number;
    visibleAppraisals: number;
    numberOfAppraisalsWithRating: number;
    appraisals: Appraisal[];
    ratings: AppraisalAverageRating;

    constructor(params: AppraisalsResource) {
        super(params);
        this.numberOfAppraisalsWithRating = params.numberOfAppraisalsWithRating;
        this.missionCount = params.missionCount;
        this.hiddenAppraisals = params.hiddenAppraisals;
        this.visibleAppraisals = params.visibleAppraisals;
        this.appraisals = params.appraisals.map((resource) => new Appraisal(resource));
        this.ratings = new AppraisalAverageRating(params.ratings);
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
