import type {BreadcrumbCategoryResource, BreadcrumbFamilyResource, BreadcrumbJobResource, BreadcrumbResource} from '~/types/api-raw-types';

export class BreadcrumbItem {
    label: string;
    url: string;

    constructor(label: string, url: string) {
        this.label = label;
        this.url = url;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class BreadcrumbItems {
    items: BreadcrumbItem[];

    constructor(items: BreadcrumbItem[]) {
        this.items = items;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class BreadcrumbJob {
    id: string;
    label: string;
    seoUrl: string;

    constructor(params: BreadcrumbJobResource) {
        this.id = params.id;
        this.label = params.label;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class BreadcrumbCategory {
    id: string;
    jobs: BreadcrumbJob[];
    label: string;
    seoUrl: string;

    constructor(params: BreadcrumbCategoryResource) {
        this.id = params.id;
        this.jobs = params.jobs.map((job) => new BreadcrumbJob(job));
        this.label = params.label;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class BreadcrumbFamily {
    categories: BreadcrumbCategory[];
    id: string;
    label: string;
    seoUrl: string;

    constructor(params: BreadcrumbFamilyResource) {
        this.categories = params.categories.map((category) => new BreadcrumbCategory(category));
        this.id = params.id;
        this.label = params.label;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export interface BreadcrumbLevels {
    family?: BreadcrumbFamily;
    category?: BreadcrumbCategory;
    job?: BreadcrumbJob;
}

export class Breadcrumb {
    families: BreadcrumbFamily[];
    preferredCategory: string | null;
    preferredFamily: string | null;
    preferredJob: string | null;
    levels: BreadcrumbLevels;

    constructor(params: BreadcrumbResource) {
        this.families = params.families.map((family) => new BreadcrumbFamily(family)) || [];
        this.preferredCategory = params.preferredCategory || null;
        this.preferredFamily = params.preferredFamily || null;
        this.preferredJob = params.preferredJob || null;
        this.levels = this.getLevels();
    }

    getLevels(): BreadcrumbLevels {
        const levels = {} as BreadcrumbLevels;
        if (this.preferredFamily && this.preferredFamily !== 'other') {
            levels.family = this.families.find((family) => family.id === this.preferredFamily);
        }

        if (levels.family && this.preferredCategory && this.preferredCategory !== 'other') {
            levels.category = levels.family.categories.find((category) => category.id === this.preferredCategory);
        }

        if (levels.category && this.preferredJob) {
            levels.job = levels.category.jobs.find((job) => job.id === this.preferredJob);
        }
        return levels;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export function getLinkedCategories(breadcrumb: Breadcrumb, familyId: string): BreadcrumbCategory[] {
    return breadcrumb.families.find((family) => family.id === familyId)?.categories || [];
}

export function getLinkedJobs(breadcrumb: Breadcrumb, familyId: string, categoryId?: string): BreadcrumbJob[] {
    return getLinkedCategories(breadcrumb, familyId).find((category) => category.id === categoryId)?.jobs || [];
}

export function getFamilySeoUrl(breadcrumb: Breadcrumb): string | undefined {
    return breadcrumb.levels.family?.seoUrl;
}

export function getCategorySeoUrl(breadcrumb: Breadcrumb): string | undefined {
    return breadcrumb.levels.category?.seoUrl;
}

export function breadcrumbItems(breadcrumb?: Breadcrumb): BreadcrumbItems {
    const items: BreadcrumbItem[] = [];
    if (!breadcrumb) {
        return new BreadcrumbItems(items);
    }

    if (breadcrumb.levels.family?.seoUrl) {
        items.push(new BreadcrumbItem(breadcrumb.levels.family.label, breadcrumb.levels.family.seoUrl));
    }
    if (breadcrumb.levels.category?.seoUrl) {
        items.push(new BreadcrumbItem(breadcrumb.levels.category.label, breadcrumb.levels.category.seoUrl));
    }
    if (breadcrumb.levels.job?.seoUrl) {
        items.push(new BreadcrumbItem(breadcrumb.levels.job.label, breadcrumb.levels.job.seoUrl));
    }
    return new BreadcrumbItems(items);
}
