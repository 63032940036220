import type {ProfileRelationsResource, Collaborators} from '~/types/infra/repository/ProfileRepositoryAPI';
import {ProfileRelationTypes} from '~/types/infra/repository/ProfileRepositoryAPI';

const experienceRelatedRelation = [
    ProfileRelationTypes.ONGOING_MISSION,
    ProfileRelationTypes.COMPLETED_MISSION,
    ProfileRelationTypes.ONGOING_EXPERIENCE,
    ProfileRelationTypes.PAST_EXPERIENCE,
];

const favoriteRelatedRelation = [ProfileRelationTypes.FAVORITE, ProfileRelationTypes.FAVORITE_OF_MINE];

export function collaboratorsMapper(relations: ProfileRelationsResource): Collaborators {
    return {
        experienceRelated: relations?.relations?.length
            ? [
                  ...new Set(
                      relations?.relations
                          .filter((relation) => experienceRelatedRelation.includes(relation.type) && relation.collaborator)
                          .map((relation) => relation.collaborator!!.name),
                  ),
              ]
            : [],
        isAddedAsFavorite: relations?.relations?.some((relation) => favoriteRelatedRelation.includes(relation.type)) || false,
    };
}
