import type {LanguageAndLevelResource, LanguageResource} from '~/types/api-raw-types';
import type {UpdateLanguageRequest} from '~/store/profileStore';

export type LanguageLevel = 'BASIC' | 'CONVERSATIONAL' | 'FLUENT' | 'NATIVE';

export class Language {
    code: string;
    name: string;

    constructor(params: LanguageResource) {
        this.code = params.code;
        this.name = params.name;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class LanguageAndLevel {
    language: Language;
    level: LanguageLevel;

    constructor(params: LanguageAndLevelResource) {
        this.language = new Language(params.language);
        this.level = params.level;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Languages {
    items: LanguageAndLevel[];

    constructor(params: LanguageAndLevelResource[]) {
        this.items = params.map((languageAndLevelResource) => new LanguageAndLevel(languageAndLevelResource));
    }

    static fromRequest(request: UpdateLanguageRequest) {
        const resource: LanguageAndLevelResource[] = request.languages.map((lang) => {
            return {
                language: {
                    code: lang.language.code,
                    name: lang.language.name,
                },
                level: lang.level,
                _links: [],
            };
        });

        return new Languages(resource);
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export function hasNativeLanguage(languages: Languages): boolean {
    return languages.items.some((languageAndLevel) => languageAndLevel.level === 'NATIVE');
}
