import type {ProfileWebPreferencesResource} from '~/types/api-raw-types';

export class ProfileWebPreferences {
    onboardingAcknowledged: boolean = false;

    constructor(params: ProfileWebPreferencesResource) {
        this.onboardingAcknowledged = params.onboardingAcknowledged;
    }

    toJSON() {
        return {
            ...this,
        };
    }

    static default(): ProfileWebPreferences {
        return new ProfileWebPreferences({
            onboardingAcknowledged: false,
        });
    }
}
