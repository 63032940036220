'use strict';
const ARG_REGEXP = /{.*?}/g;
class UrlService {
    buildUrl(urlTpl, ...ids) {
        const args = Array.isArray(ids) ? [...ids] : [ids];
        return urlTpl.replace(ARG_REGEXP, () => args.shift());
    }
    extractIdsFromUrl(urlTpl, url) {
        const urlTplParts = urlTpl.split('/').slice(1);
        const urlParts = url.split('/').slice(1);
        const idIdxs = urlTplParts.reduce((acc, el, i) => {
            return el.match(ARG_REGEXP) ? [...acc, i] : acc;
        }, []);
        return urlParts.filter((el, idx) => {
            return idIdxs.includes(idx);
        });
    }
    mapIdsFromUrl(urlTpl, url) {
        const urlTplParts = urlTpl.split('/').slice(1);
        const urlParts = url.split('/').slice(1);
        return urlTplParts.reduce((result, el, index) => el.match(ARG_REGEXP) && urlParts[index] ? { ...result, [el.slice(1, -1)]: urlParts[index] } : result, {});
    }
}
export const urlService = new UrlService();
