import type {ResourceLink} from '~/types/api-raw-types';

export function extractLink(links: ResourceLink[], rel: string): string {
    const maybeLink = extractMaybeLink(links, rel);

    if (!maybeLink) {
        throw new Error(`extractLink: key "${rel}" doesn't exist. Got:${links.map((link) => link.rel).join(',')}`);
    }

    return links.find((link) => link.rel === rel)!.href;
}

export function extractMaybeLink(links: ResourceLink[], rel: string): string | undefined {
    const maybeLink = links.find((link) => link.rel === rel);

    return maybeLink ? maybeLink.href : undefined;
}
