import type {CategorizationCategoryResource, CategorizationFamilyResource, CategorizationResource} from '~/types/api-raw-types';

export class CategorizationCategory {
    id: string;
    label: string;
    seoUrl: string;

    constructor(params: CategorizationCategoryResource) {
        this.id = params.id;
        this.label = params.label;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class CategorizationFamily {
    categories: CategorizationCategory[];
    id: string;
    label: string;
    pictoName: string;
    seoUrl: string;

    constructor(params: CategorizationFamilyResource) {
        this.categories = params.categories.map((category) => new CategorizationCategory(category));
        this.id = params.id;
        this.label = params.label;
        this.pictoName = params.pictoName;
        this.seoUrl = params.seoUrl;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Categorization {
    families: CategorizationFamily[];
    preferredCategory: string;
    preferredFamily: string;

    constructor(params: CategorizationResource) {
        this.families = params.families.map((family) => new CategorizationFamily(family));
        this.preferredCategory = params.preferredCategory;
        this.preferredFamily = params.preferredFamily;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export function orderedFamilies(categorization: Categorization) {
    return categorization.families.sort((first, second) => first.label.localeCompare(second.label));
}

export function orderedCategories(categories: CategorizationCategory[]) {
    return categories.sort((first, second) => first.label.localeCompare(second.label));
}
