import type {CharterStatusResource, ChecklistResource, ComplianceCheckResource, DateAsString, EmailValidationResource} from '~/types/api-raw-types';

export class EmailValidation {
    valid: boolean;

    constructor(params: EmailValidationResource) {
        this.valid = params.valid;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class ComplianceCheck {
    companyComplianceUpToDate: boolean;
    companyVerified: boolean;
    display: boolean;

    constructor(params: ComplianceCheckResource) {
        this.companyComplianceUpToDate = params.companyComplianceUpToDate;
        this.companyVerified = params.companyVerified;
        this.display = params.display;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class CharterStatus {
    charterInvalidUntil: DateAsString;
    signed: boolean;
    valid: boolean;

    constructor(params: CharterStatusResource) {
        this.charterInvalidUntil = params.charterInvalidUntil;
        this.signed = params.signed;
        this.valid = params.valid;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class VerificationChecklist {
    charter: CharterStatus;
    complianceCheck: ComplianceCheck;
    emailValidation: EmailValidation;

    constructor(params: ChecklistResource) {
        this.charter = new CharterStatus(params.charter);
        this.complianceCheck = new ComplianceCheck(params.complianceCheck);
        this.emailValidation = new EmailValidation(params.emailValidation);
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
