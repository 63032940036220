import {callOnce, defineNuxtPlugin, useAuth, useLocale, useMaltVisitorId} from '#imports';
import type {Pinia} from 'pinia';

export default defineNuxtPlugin({
    name: 'fetch-current-session',
    dependsOn: ['backend-api-cookies'],
    async setup(nuxtApp) {
        const $pinia = nuxtApp.$pinia as Pinia;
        const authStore = useAuth($pinia);

        const maltVisitorId = useMaltVisitorId();
        const {updateLocale} = useLocale();

        await callOnce('current-session', async () => {
            await authStore.checkIfLoggedOrIfNeedingRedirect();

            if (authStore.userVisitorId) {
                maltVisitorId.value = authStore.userVisitorId;
            }

            if (authStore.userLocale) {
                updateLocale(authStore.userLocale);
            }
        });

        return {
            provide: {
                isPluginFetchCurrentSessionEnabled: true,
            },
        };
    },
});
