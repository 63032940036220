import {type ProfileSelectedSkillSetItem, SelectedSkillType} from '~/types/domain/ProfileExpertises';
import type {UpdateIndustryExpertisesOperationRequest, UpdateSkillsRequest} from '@api';
import {type UpdateProfileSkillRequest, UpdateProfileSkillRequestTypeEnum} from '@api/models';
import type {UpdateIndustryExpertiseRequest} from '~/types/domain/skill/Industry';

export const expertiseApiMapper = {
    toUpdateSkillsRequestApi: (
        newSelectedSkills: ProfileSelectedSkillSetItem[],
        newTopSkills: ProfileSelectedSkillSetItem[],
    ): UpdateSkillsRequest => {
        return {
            updateProfileSkillsRequest: {
                topSkills: expertiseApiMapper.toRequestApi(newTopSkills),
                selectedSkillsOrder: expertiseApiMapper.toRequestApi(newSelectedSkills),
            },
        };
    },

    toRequestApi: (skills: ProfileSelectedSkillSetItem[]): UpdateProfileSkillRequest[] => {
        return skills.map((skill) => ({
            id: skill.id,
            type:
                skill.type === SelectedSkillType.FUNCTIONAL ? UpdateProfileSkillRequestTypeEnum.Functional : UpdateProfileSkillRequestTypeEnum.Global,
        }));
    },

    toUpdateIndustryExpertisesRequestApi: (request: UpdateIndustryExpertiseRequest): UpdateIndustryExpertisesOperationRequest => {
        return {updateIndustryExpertisesRequest: request};
    },
};
