import {defineStore} from 'pinia';
import type {Ref} from 'vue';
import {ref} from 'vue';
import {useAsyncData, useDataTracking, useLogger, useMaltVisitorId, useUniversalFetch} from '#imports';

type Campaigns<T extends string[]> = Record<T[number], string>;

/**
 * This function will create a Pinia store that can fetch and infer types of your AB Test campaigns
 * It's designed to be used at app start and persist during app lifecycle
 *
 * @param [storeName="abtest-store"] - Override store name
 *
 * @usage
 *
 * ```ts
 * const useABTestStore = createABTestStore<['my-campaign-1', 'my-campaign-2']>();
 *
 * const ABTestStore = useABTestStore();
 * await ABTestStore.loadCampaigns();
 *
 * const isMyCampaignEnabled = ABTestStore.campaigns['my-logged-campaign-1']; // Typed and reactive
 * ```
 *
 */
export function createABTestStore<T extends string[] = []>(storeName = 'abtest-store') {
    return defineStore(storeName, () => {
        const campaigns = ref({}) as Ref<Campaigns<T>>;
        const logger = useLogger();
        const maltVisitorId = useMaltVisitorId();
        const {track} = useDataTracking();

        async function fetchCampaignsVariations() {
            const {data, error} = await useAsyncData('abtest-campaigns-variations', () =>
                useUniversalFetch<Campaigns<T>>(`/public-api/experiment/campaigns?maltVisitorId=${maltVisitorId.value}`),
            );
            if (error.value) {
                logger.error(`Error while fetching campaigns variations for visitor ${maltVisitorId.value}`, error.value);
            }
            campaigns.value = data.value as Campaigns<T>;
        }

        const getVariation = (campaignId: string): string | null => {
            if (!campaigns.value) {
                logger.error('Campaigns should not be null');
                return null;
            }
            const variationId = campaigns.value[campaignId as keyof typeof campaigns.value];
            if (variationId) {
                // eslint-disable-next-line @malt/custom-rules/tracking-event-name
                track('$exposure', {flag_key: campaignId, variant: variationId});
            }
            return variationId;
        };

        return {
            fetchCampaignsVariations,
            getVariation,
        };
    });
}
