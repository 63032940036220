import {useUniversalFetch} from '#imports';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {PortfolioWithItemsResource} from '~~/src/types/api-raw-types';
import type {Profile} from '~/types/domain/Profile';
import type {PortfolioRepository} from '../../domain/repository/PortfolioRepository';
import {Portfolio} from '../../domain/Portfolio';

async function fetchPortfolio(profile: Profile): Promise<Portfolio> {
    try {
        const portfolio = profile.portfolio;

        const url = extractLink(portfolio.links, 'items');
        const portfolioResource = await useUniversalFetch<PortfolioWithItemsResource>(url);
        portfolio.updateItems(portfolioResource);

        return portfolio;
    } catch (e) {
        return Portfolio.empty();
    }
}

export const portfolioRepository: PortfolioRepository = {
    fetchPortfolio,
};
