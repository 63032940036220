import type {CertificationResource} from '~/types/api-raw-types';

export class Certification {
    graduationYear: number;
    id: string;
    link: string;
    organization: string;
    skills: string[];
    title: string;

    constructor(params: CertificationResource) {
        this.graduationYear = params.graduationYear;
        this.id = params.id;
        this.link = params.link;
        this.organization = params.organization;
        this.skills = params.skills;
        this.title = params.title;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Certifications {
    items: Certification[];

    constructor(params: CertificationResource[]) {
        this.items = params.map((certificationResource) => new Certification(certificationResource));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
