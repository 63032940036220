
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';



import undefined from '@malt/joy-entrypoint/src/vue/plugins/external/overrides/primevue/joy-primevue-theme/theme.ts';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = {};
  const theme = { theme: undefined || options?.theme };

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt, ...theme });
  
  
});
        