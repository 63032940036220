import type {EducationResource} from '~/types/api-raw-types';

export class Education {
    content: string;
    description: string;
    graduationYear: number;
    title: string;

    constructor(params: EducationResource) {
        this.content = params.content;
        this.description = params.description;
        this.graduationYear = params.graduationYear;
        this.title = params.title;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class Educations {
    items: Education[];

    constructor(params: EducationResource[]) {
        this.items = params.map((educationResource) => new Education(educationResource));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
