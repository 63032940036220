import type {IndustryExpertiseResource} from '~/types/api-raw-types';

export interface IndustryExpertiseItem {
    id: string;
    label: string;
}

export type UpdateIndustryExpertiseRequest = {
    expertises: string[];
};

export class IndustryExpertise {
    id: string;
    label: string;

    constructor(params: IndustryExpertiseResource) {
        this.id = params.humanId;
        this.label = params.label;
    }

    toJSON() {
        return {
            ...this,
        };
    }
}

export class IndustryExpertisesAvailable {
    all: IndustryExpertise[];

    constructor(params: IndustryExpertiseResource[]) {
        this.all = params.map((res) => new IndustryExpertise(res));
    }

    toJSON() {
        return {
            ...this,
        };
    }
}
